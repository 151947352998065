import { useTheme } from "@mui/system";
import { mutationPostPixClaimCreate } from "api/dictv2-api";
import BaasButton from "components/atoms/Button/Button";
import BaasFlex from "components/atoms/Flex/Flex";
import BaasGrid from "components/atoms/Grid/Grid";
import BaasRow from "components/atoms/Grid/Row";
import BaasMenuItem from "components/atoms/MenuItem/MenuItem";
import BaasText from "components/atoms/Text/BaasText";
import BaasTextField from "components/atoms/TextField/TextField";
import BaasPageHeader from "components/molecules/PageHeader/PageHeader";
import BaasOtpModal from "components/organism/OtpModal/OtpModal";
import BaasContentGrid from "components/templates/ContentGrid/ContentGrid";
import BaasTransactionSuccess from "components/templates/TransactionSuccess/TransactionSuccess";
import { toastMessages } from "constants/toastMessages";
import { Form, Formik } from "formik";
import { formatPixKeys } from "helpers/formatPixKeys";
import { useOtp } from "providers/oneTimePassword";
import React, { useCallback, useMemo, useState, useEffect } from "react";
import InputMask from "react-input-mask/lib/react-input-mask.development";
import { useHistory } from "react-router";
import { toast } from "react-toastify";
import * as yup from "yup";
import AccountTypeSelectedLabel from "components/molecules/AccountTypeSelectedLabel/AccountTypeSelectedLabel";
import { useAccount } from "providers/account";

const ClaimCreateV2 = () => {
  const theme = useTheme();
  const { setOpen, isOtpRequired } = useOtp();
  const { accountType } = useAccount();

  const [success, setSuccess] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const history = useHistory();
  const initialValues = useMemo(() => {
    return {};
  }, []);

  const validationSchema = yup.object().shape({});

  const mutateClaim = useCallback(async (values) => {
    const response = await mutationPostPixClaimCreate(values);
    if (response.status < 300) {
      toast.success(toastMessages.requestSuccess);
      setSuccess(true);
    } else {
      if ("errors" in response) {
        response.errors.map((error) => {
          return toast.error(error.message);
        });
      }
    }
    setIsSubmitting(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (accountType === "Merchant") {
      handleBackToDashboard();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [accountType]);

  const handleBackToDashboard = () => {
    history.push("/dashboard");
  };

  const handleBack = () => {
    history.push("/pix/v2/dict-claim");
  };

  const onSubmit = useCallback(
    (values) => {
      setIsSubmitting(true);
      mutateClaim({
        otp: values.otp,
        accountNumber: values.accountNumber,
        accountType: values.accountType,
        branch: values.branch,
        key: formatPixKeys(values),
        name: values.name,
        taxId: values.taxId,
        type: values.type,
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [mutateClaim]
  );

  return (
    <>
      <BaasFlex flex={1} flexDirection="column">
        <BaasPageHeader>DICT Hub</BaasPageHeader>

        <BaasContentGrid spacing={4}>
          <Formik
            initialValues={initialValues}
            onSubmit={isOtpRequired ? () => setOpen(true) : onSubmit}
            validationSchema={validationSchema}
          >
            {({ values, ...props }) => (
              <Form>
                <BaasOtpModal
                  onConfirm={() => onSubmit(values, props)}
                  {...props}
                />
                <BaasGrid p="16px 0px" item>
                  <BaasGrid display="flex">
                    <BaasText variant="h6">Create New DICT Claim</BaasText>
                    <AccountTypeSelectedLabel />
                  </BaasGrid>
                </BaasGrid>
                {!success ? (
                  <BaasRow>
                    <BaasGrid
                      p="16px"
                      sx={{ background: theme.palette.grey[100] }}
                    >
                      <BaasText variant="body2">
                        Creates a new pix claim for an existing pix key.
                      </BaasText>

                      <BaasRow container column={12} spacing={3}>
                        <BaasGrid item xs={12} md={6}>
                          <BaasTextField
                            fullWidth
                            name="type"
                            label="Claim Type"
                            required
                            select
                          >
                            <BaasMenuItem value="OWNERSHIP">
                              Ownership
                            </BaasMenuItem>
                            <BaasMenuItem value="PORTABILITY">
                              Portability
                            </BaasMenuItem>
                          </BaasTextField>
                        </BaasGrid>
                        <BaasGrid item xs={12} md={6}>
                          <BaasTextField
                            fullWidth
                            name="keyType"
                            label="Key Type"
                            required
                            select
                          >
                            <BaasMenuItem value="keyCpf">CPF</BaasMenuItem>
                            <BaasMenuItem value="keyCnpj">CNPJ</BaasMenuItem>
                            <BaasMenuItem value="keyPhone">Phone</BaasMenuItem>
                            <BaasMenuItem value="keyEmail">Email</BaasMenuItem>
                          </BaasTextField>
                        </BaasGrid>
                        {/* CPF */}
                        {values.keyType === "keyCpf" ? (
                          <BaasGrid item xs={12}>
                            <InputMask
                              mask="999.999.999-99"
                              onChange={(e) => (values.key = e.target.value)}
                            >
                              <BaasTextField
                                fullWidth
                                name="key"
                                label="Key"
                                required
                              />
                            </InputMask>
                          </BaasGrid>
                        ) : null}
                        {/* CNPJ */}
                        {values.keyType === "keyCnpj" ? (
                          <BaasGrid item xs={12}>
                            <InputMask
                              mask="99.999.999/9999-99"
                              onChange={(e) => (values.key = e.target.value)}
                            >
                              <BaasTextField
                                fullWidth
                                name="key"
                                label="Key"
                                required
                              />
                            </InputMask>
                          </BaasGrid>
                        ) : null}
                        {/* Phone */}
                        {values.keyType === "keyPhone" ? (
                          <BaasGrid item xs={12}>
                            <InputMask
                              mask="(99) 99999-9999"
                              onChange={(e) => (values.key = e.target.value)}
                            >
                              <BaasTextField
                                fullWidth
                                name="key"
                                label="Key"
                                required
                              />
                            </InputMask>
                          </BaasGrid>
                        ) : null}
                        {/* Email */}
                        {values.keyType === "keyEmail" ? (
                          <BaasGrid item xs={12}>
                            <BaasTextField
                              fullWidth
                              name="key"
                              label="Key"
                              required
                              type="email"
                              placeholder="email@example.com"
                            />
                          </BaasGrid>
                        ) : null}
                        <BaasGrid item xs={12} md={6}>
                          <BaasTextField
                            fullWidth
                            name="name"
                            label="Name"
                            required
                          />
                        </BaasGrid>
                        <BaasGrid item xs={12} md={6}>
                          <BaasTextField
                            fullWidth
                            name="branch"
                            label="Branch"
                            type="number"
                            required
                          />
                        </BaasGrid>
                        <BaasGrid item xs={12} md={6}>
                          <BaasTextField
                            fullWidth
                            name="accountNumber"
                            label="Account"
                            type="number"
                            required
                          />
                        </BaasGrid>
                        <BaasGrid item xs={12} md={6}>
                          <BaasTextField
                            fullWidth
                            name="accountType"
                            label="Account Type"
                            required
                            select
                          >
                            <BaasMenuItem value="CACC">CACC</BaasMenuItem>
                            <BaasMenuItem value="SLRY">SLRY</BaasMenuItem>
                            <BaasMenuItem value="SVGS">SVGS</BaasMenuItem>
                            <BaasMenuItem value="TRAN">TRAN</BaasMenuItem>
                          </BaasTextField>
                        </BaasGrid>
                        <BaasGrid item xs={12} md={6}>
                          <BaasTextField
                            fullWidth
                            name="taxId"
                            label="Tax Id"
                            type="number"
                            required
                          />
                        </BaasGrid>
                      </BaasRow>

                      <BaasRow
                        container
                        sx={{ justifyContent: "space-between" }}
                        flexDirection="row"
                      >
                        <BaasGrid p={0} item>
                          <BaasButton
                            type="submit"
                            size="medium"
                            variant="outlined"
                            backgroundColor="black"
                            onClick={handleBack}
                          >
                            Back
                          </BaasButton>
                        </BaasGrid>
                        <BaasGrid p={0} item>
                          <BaasButton
                            type="submit"
                            size="medium"
                            variant="contained"
                            backgroundColor="black"
                            loading={isSubmitting}
                          >
                            Create
                          </BaasButton>
                        </BaasGrid>{" "}
                      </BaasRow>
                    </BaasGrid>
                  </BaasRow>
                ) : (
                  <>
                    <BaasTransactionSuccess redirectUrl="/pix/v2/dict-claim" />
                  </>
                )}
              </Form>
            )}
          </Formik>
        </BaasContentGrid>
      </BaasFlex>
    </>
  );
};

export default ClaimCreateV2;
