import { Box, Typography, useTheme, Popover } from "@mui/material";
import BaasGrid from "components/atoms/Grid/Grid";
import { BarChart } from "./BarChart";
import { useState } from "react";
import guidGenerator from "services/guidGenerator";
import HourglassEmptyIcon from "@mui/icons-material/HourglassEmpty";
import BaasText from "components/atoms/Text/BaasText";
import BaasSkeleton from "components/atoms/Skeleton/Skeleton";

const MetricsPanel = ({ rightCard, leftCard, chartData, loadingDashboard }) => {
  const theme = useTheme();
  const [anchorEl, setAnchorEl] = useState(null);
  const [instruction, setInstruction] = useState("");

  const handlePopoverOpen = (event, instruction) => {
    if (instruction) {
      setInstruction(instruction);
      setAnchorEl(event.currentTarget);
    }
  };

  const handlePopoverClose = () => {
    setInstruction(null);
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const cardStyle = {
    border: `1px solid ${theme.palette.grey[500]}`,
    width: "100%",
    height: "200px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-evenly",
    alignItems: "center",
    cursor: "pointer",
  };

  if (loadingDashboard) {
    return (
      <BaasGrid item xs={12} sm={12} md={3}>
        <BaasSkeleton width="100%" height="200px" />
      </BaasGrid>
    );
  }

  return (
    <>
      <Popover
        sx={{
          pointerEvents: "none",
        }}
        open={open}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        onClose={handlePopoverClose}
        disableRestoreFocus
        container={anchorEl?.parentNode}
      >
        <Typography sx={{ p: 1 }} display="flex" alignItems="center">
          {instruction || ""}
        </Typography>
      </Popover>

      <Box ml={2}>
        <BaasGrid
          pb={2}
          pr={2}
          container
          spacing={2}
          sx={{ background: theme.palette.grey[100] }}
        >
          <BaasGrid
            item
            xs={12}
            sm={12}
            md={3}
            onMouseEnter={
              rightCard?.instruction
                ? (e) => handlePopoverOpen(e, rightCard?.instruction || "")
                : () => {}
            }
            onMouseLeave={handlePopoverClose}
          >
            <Box sx={cardStyle}>
              <Box textAlign="center">
                <Typography>{rightCard.title}</Typography>
                {rightCard.subtitle && (
                  <Typography color="textSecondary">
                    {rightCard.subtitle}
                  </Typography>
                )}
              </Box>
              <Typography
                sx={{
                  fontSize: "20px",
                  fontWeight: "bold",
                  color: `${chartData.color}`,
                }}
              >
                {rightCard.value}
              </Typography>
            </Box>{" "}
          </BaasGrid>
          <BaasGrid
            item
            xs={12}
            sm={12}
            md={6}
            onMouseEnter={(e) =>
              handlePopoverOpen(e, chartData?.instruction || "")
            }
            onMouseLeave={handlePopoverClose}
          >
            <Box sx={cardStyle}>
              {chartData.items.length > 0 ? (
                <BarChart chartData={chartData} key={guidGenerator()} />
              ) : (
                <Box
                  style={{ background: theme.palette.grey[100] }}
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  p={2}
                >
                  <HourglassEmptyIcon
                    style={{ color: theme.palette.grey[600] }}
                  />
                  <BaasText style={{ color: theme.palette.grey[600] }}>
                    No Data Available
                  </BaasText>
                </Box>
              )}
            </Box>
          </BaasGrid>
          <BaasGrid
            item
            xs={12}
            sm={12}
            md={3}
            onMouseEnter={(e) =>
              handlePopoverOpen(e, leftCard?.instruction || "")
            }
            onMouseLeave={handlePopoverClose}
          >
            {leftCard && (
              <Box sx={cardStyle}>
                <Box textAlign="center">
                  <Typography>{leftCard.title}</Typography>
                  {leftCard.subtitle && (
                    <Typography color="textSecondary">
                      {leftCard.subtitle}
                    </Typography>
                  )}
                </Box>
                <Typography
                  sx={{
                    fontSize: "20px",
                    fontWeight: "bold",
                    color: `${chartData.color}`,
                  }}
                >
                  {leftCard.value}
                </Typography>
              </Box>
            )}
          </BaasGrid>
        </BaasGrid>
      </Box>
    </>
  );
};
export default MetricsPanel;
