import { useTheme } from "@mui/system";
import { queryGetPixInfractionReportSearch } from "api/dictv2-api";
import BaasButton from "components/atoms/Button/Button";
import BaasCheckbox from "components/atoms/Checkbox/Checkbox";
import BaasDatePicker from "components/atoms/DatePicker/DatePicker";
import BaasGrid from "components/atoms/Grid/Grid";
import BaasMenuItem from "components/atoms/MenuItem/MenuItem";
import BaasText from "components/atoms/Text/BaasText";
import BaasTextField from "components/atoms/TextField/TextField";
import { toastMessages } from "constants/toastMessages";
import { Form, Formik } from "formik";
import _ from "lodash";
import moment from "moment";
import { useAccount } from "providers/account";
import React, { useCallback, useState } from "react";
import { useHistory } from "react-router";
import { toast } from "react-toastify";
import InfractionReportSearchList from "./InfractionReportSearchList";

const InfractionReportSearch = (props) => {
  const theme = useTheme();
  const { accountType } = useAccount();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [data, setData] = useState([]);
  const [queryVariables, setQueryVariables] = useState();
  const [inputFilter, setInputFilter] = useState();
  const [pagination, setPagination] = useState();

  const history = useHistory();

  const handleAppendData = (response, oldData) => {
    if (accountType === "Indirect") {
      setPagination({
        hasMoreElements: response.body?.hasMoreElements,
        lastModified:
          response.body?.items[response.body.items.length - 1]?.lastModified,
      });
      setData(
        oldData
          ? _.uniqBy([...oldData, ...response.body?.items], "id")
          : response.body?.items
      );
    } else {
      if (oldData) {
        setPagination({
          hasMoreElements: response.body?.hasMoreElements,
          nextPage: response.body?.nextPage,
        });
        setData([...oldData, ...response.body?.items]);
      } else {
        setPagination({
          hasMoreElements: response.body?.hasMoreElements,
          nextPage: response.body?.nextPage,
        });
        setData(response.body?.items);
      }
    }
  };

  const searchInfraction = useCallback(async (values, oldData) => {
    const response = await queryGetPixInfractionReportSearch(values);

    if (response.status < 300) {
      if (response.body.items.length === 0)
        toast.warning(toastMessages.search.searchEmpty);
      handleAppendData(response, oldData);
    } else {
      setData([]);
      if ("errors" in response) {
        response.errors.forEach((error) => {
          toast.error(error.message);
        });
      } else {
        toast.error(toastMessages.requestError);
      }
    }
    setIsSubmitting(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleRedirect = useCallback(() => {
    history.push("/pix/v2/infraction-report/create");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [history]);

  const handleRefetch = () => {
    setInputFilter(null);
    setIsSubmitting(true);
    searchInfraction(queryVariables);
  };

  const onSubmit = useCallback(
    (values) => {
      const params = {
        modifiedAfter: `${moment(values.modifiedAfter).format(
          "YYYY-MM-DD"
        )}T00:00:00Z`,
        modifiedBefore: `${moment(values.modifiedBefore).format(
          "YYYY-MM-DD"
        )}T23:59:59Z`,
        status: values.status,
        limit: values.limit,
        isReporter: values.isReporter ? true : undefined,
        isCounterParty: values.isCounterParty ? true : undefined,
        includeDetails: values.includeDetails,
        accountType: accountType,
        taxId: values.taxId,
      };
      setQueryVariables(params);
      setInputFilter(null);
      setIsSubmitting(true);
      searchInfraction(params);
    },
    [accountType, searchInfraction]
  );

  const handleLoadMore = () => {
    const queryPaginatedVariables =
      accountType === "Indirect"
        ? {
            ...queryVariables,
            modifiedAfter: pagination.lastModified,
          }
        : {
            ...queryVariables,
            nextPage: pagination.nextPage,
          };
    setQueryVariables(queryPaginatedVariables);
    setInputFilter(null);
    searchInfraction(queryPaginatedVariables, data);
  };

  const isPixLimitValidatorRoute =
    props?.location?.pathname === "/pix-limit-validator-infraction";

  return (
    <>
      <Formik
        initialValues={{
          limit: "20",
        }}
        onSubmit={onSubmit}
        enableReinitialize
      >
        {({ values, setFieldValue, ...props }) => {
          let modifiedBefore = moment(values.modifiedBefore);
          let modifiedAfter = moment(values.modifiedAfter);
          const differenceInDates = modifiedBefore.diff(modifiedAfter, "days");
          const errorInDates = differenceInDates > 3 || differenceInDates < 0;
          return (
            <Form>
              <BaasGrid p="16px" sx={{ background: theme.palette.grey[100] }}>
                <BaasText>
                  Transactions under suspicion of fraud (FRAUD) or PLD/FT
                  (AML_CFT) can be reported by either their debited or credited
                  participants.
                </BaasText>

                {!isPixLimitValidatorRoute && (
                  <BaasGrid item p="16px 0px">
                    <BaasButton
                      onClick={handleRedirect}
                      variant="contained"
                      backgroundColor="black"
                      type="submit"
                    >
                      Create new infraction
                    </BaasButton>
                  </BaasGrid>
                )}

                <BaasGrid container spacing={3}>
                  <BaasGrid item xs={12} sm={6}>
                    <BaasDatePicker
                      name="modifiedAfter"
                      // customOnChange={(date) => {
                      //   setFieldValue(
                      //     "modifiedBefore",
                      //     moment(date).add(3, "days")
                      //   );
                      // }}
                      label="Modified after"
                      fullWidth
                    />
                  </BaasGrid>

                  <BaasGrid item xs={12} sm={6}>
                    <BaasDatePicker
                      name="modifiedBefore"
                      label="Modified before"
                      // maxDate={moment(values.modifiedAfter).add(3, "days")}
                      // minDate={moment(values.modifiedAfter)}
                      fullWidth
                    />
                  </BaasGrid>

                  {accountType === "Indirect" && (
                    <BaasGrid item xs={12} sm={6}>
                      <BaasTextField
                        fullWidth
                        name="taxId"
                        label="Tax Id"
                        type="text"
                      />
                    </BaasGrid>
                  )}

                  {/* <BaasGrid item xs={12} sm={6}>
                    <BaasTextField
                      fullWidth
                      name="reportedBy"
                      label="Reported by"
                      select
                    >
                      <BaasMenuItem value="">Select</BaasMenuItem>
                      <BaasMenuItem value="isReporter">
                        This client
                      </BaasMenuItem>
                      <BaasMenuItem value="isCounterParty">
                        Third party
                      </BaasMenuItem>
                    </BaasTextField>
                  </BaasGrid> */}

                  <BaasGrid item xs={12} sm={6}>
                    <BaasTextField
                      fullWidth
                      name="status"
                      label="Status"
                      select
                    >
                      <BaasMenuItem value="">--</BaasMenuItem>
                      <BaasMenuItem value="OPEN">Open</BaasMenuItem>
                      <BaasMenuItem value="ACKNOWLEDGED">
                        Acknowledged
                      </BaasMenuItem>
                      <BaasMenuItem value="CLOSED">Closed</BaasMenuItem>
                      <BaasMenuItem value="CANCELLED">Cancelled</BaasMenuItem>
                    </BaasTextField>
                  </BaasGrid>

                  <BaasGrid item xs={12} sm={6}>
                    <BaasTextField
                      fullWidth
                      name="limit"
                      label="Max items displayed"
                      select
                      required
                    >
                      <BaasMenuItem value="20">20</BaasMenuItem>
                      <BaasMenuItem value="50">50</BaasMenuItem>
                      <BaasMenuItem value="100">100</BaasMenuItem>
                    </BaasTextField>
                  </BaasGrid>
                  <BaasGrid item xs={6}>
                    <BaasCheckbox
                      name="isReporter"
                      label="Filter infractions that client is the creator"
                      fullWidth
                    />
                  </BaasGrid>
                  <BaasGrid item xs={6}>
                    <BaasCheckbox
                      name="isCounterParty"
                      label="Filter infractions that client is reported"
                      fullWidth
                    />
                  </BaasGrid>
                  <BaasGrid item xs={12}>
                    <BaasCheckbox
                      name="includeDetails"
                      label="Includes the details of the report (Details fields). Default: False."
                      fullWidth
                    />
                  </BaasGrid>
                </BaasGrid>
                <BaasGrid item p="16px 0px">
                  <BaasButton
                    variant="contained"
                    backgroundColor="black"
                    loading={isSubmitting}
                    // disabled={errorInDates}
                    type="submit"
                  >
                    Search
                  </BaasButton>
                </BaasGrid>
              </BaasGrid>
            </Form>
          );
        }}
      </Formik>
      {data.length > 0 && (
        <InfractionReportSearchList
          data={data}
          handleRefetch={handleRefetch}
          loading={isSubmitting}
          loadMore={handleLoadMore}
          inputFilter={inputFilter}
          useLoadMore
          setInputFilter={setInputFilter}
          pagination={pagination}
          isPixLimitValidatorRoute={isPixLimitValidatorRoute}
        />
      )}
    </>
  );
};

export default InfractionReportSearch;
