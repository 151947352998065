import { useTheme } from "@mui/system";
import { mutationCreateFraudMarker } from "api/dictv2-api";
import BaasButton from "components/atoms/Button/Button";
import BaasFlex from "components/atoms/Flex/Flex";
import BaasGrid from "components/atoms/Grid/Grid";
import BaasRow from "components/atoms/Grid/Row";
import BaasMenuItem from "components/atoms/MenuItem/MenuItem";
import BaasText from "components/atoms/Text/BaasText";
import BaasTextField from "components/atoms/TextField/TextField";
import BaasPageHeader from "components/molecules/PageHeader/PageHeader";
import BaasOtpModal from "components/organism/OtpModal/OtpModal";
import BaasContentGrid from "components/templates/ContentGrid/ContentGrid";
import BaasTransactionSuccess from "components/templates/TransactionSuccess/TransactionSuccess";
import { toastMessages } from "constants/toastMessages";
import { Form, Formik } from "formik";
import { useAccount } from "providers/account";
import { useOtp } from "providers/oneTimePassword";
import React, { useCallback, useMemo, useState, useEffect } from "react";
import { useHistory } from "react-router";
import { toast } from "react-toastify";
import guidGenerator from "services/guidGenerator";
import * as yup from "yup";
import AccountTypeSelectedLabel from "components/molecules/AccountTypeSelectedLabel/AccountTypeSelectedLabel";

const AntiFraudCreate = () => {
  const theme = useTheme();
  const { accountType } = useAccount();
  const { setOpen, isOtpRequired } = useOtp();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [success, setSuccess] = useState(false);
  const history = useHistory();

  const initialValues = useMemo(() => {
    return {};
  }, []);

  const validationSchema = yup.object().shape({});

  const mutateCreateFraudMarker = useCallback(async (values) => {
    const response = await mutationCreateFraudMarker(values);
    if (response.status < 300) {
      toast.success(toastMessages.requestSuccess);
      setSuccess(true);
    } else {
      if ("errors" in response) {
        response.errors.map((error) => {
          return toast.error(error.message);
        });
      }
    }
    setIsSubmitting(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (accountType === "Merchant") {
      handleBackToDashboard();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [accountType]);

  const handleBackToDashboard = () => {
    history.push("/dashboard");
  };

  const handleBack = () => {
    history.push("/pix/v2/anti-fraud");
  };

  const onSubmit = useCallback(
    (values) => {
      setIsSubmitting(true);
      mutateCreateFraudMarker({
        otp: values.otp,
        taxIdNumber: values.taxIdNumber,
        fraudType: values.fraudType,
        key: values.key,
        clientRequestId: guidGenerator(),
        accountType: accountType,
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [mutateCreateFraudMarker, setOpen]
  );

  const fraudTypeOptions = [
    {
      value: "APPLICATION_FRAUD",
      label: "APPLICATION FRAUD",
    },
    {
      value: "MULE_ACCOUNT",
      label: "MULE ACCOUNT",
    },
    {
      value: "SCAMMER_ACCOUNT",
      label: "SCAMMER ACCOUNT",
    },
    {
      value: "OTHER",
      label: "OTHER",
    },
    // {
    //   value: "UNKNOWN",
    //   label: "UNKNOWN",
    // },
  ];

  return (
    <>
      <BaasFlex flex={1} flexDirection="column">
        <BaasPageHeader>DICT Hub</BaasPageHeader>
        <BaasContentGrid spacing={4}>
          <Formik
            initialValues={initialValues}
            onSubmit={isOtpRequired ? () => setOpen(true) : onSubmit}
            validationSchema={validationSchema}
          >
            {({ values, ...props }) => (
              <Form>
                <BaasOtpModal
                  onConfirm={() => onSubmit(values, props)}
                  {...props}
                />
                <BaasGrid p="16px 0px" item>
                  <BaasGrid display="flex">
                    <BaasText variant="h6">Create New Anti Fraud Marker</BaasText>
                    <AccountTypeSelectedLabel />
                  </BaasGrid>
                </BaasGrid>
                {!success ? (
                  <BaasRow>
                    <BaasGrid
                      p="16px"
                      sx={{ background: theme.palette.grey[100] }}
                    >
                      <BaasText variant="body2">
                        Creates a transactional fraud marker. All created fraud
                        markers are accounted for and returned to query
                        statistics.
                      </BaasText>

                      <BaasRow container column={12} spacing={3}>
                        <BaasGrid item xs={12} sm={6}>
                          <BaasTextField
                            fullWidth
                            name="fraudType"
                            label="Fraud Type"
                            select
                            required
                          >
                            {fraudTypeOptions.map((type) => (
                              <BaasMenuItem key={type.value} value={type.value}>
                                {type.label}
                              </BaasMenuItem>
                            ))}
                          </BaasTextField>
                        </BaasGrid>

                        <BaasGrid item xs={12} sm={6}>
                          <BaasTextField
                            fullWidth
                            required
                            name="taxIdNumber"
                            label="Tax Id"
                          />
                        </BaasGrid>

                        <BaasGrid item xs={12} sm={6}>
                          <BaasTextField fullWidth name="key" label="Key" />
                        </BaasGrid>
                      </BaasRow>

                      <BaasRow
                        container
                        sx={{ justifyContent: "space-between" }}
                        flexDirection="row"
                      >
                        <BaasGrid p={0} item>
                          <BaasButton
                            type="submit"
                            size="medium"
                            variant="outlined"
                            backgroundColor="black"
                            onClick={handleBack}
                            loading={isSubmitting}
                          >
                            Back
                          </BaasButton>
                        </BaasGrid>
                        <BaasGrid p={0} item>
                          <BaasButton
                            type="submit"
                            size="medium"
                            variant="contained"
                            backgroundColor="black"
                            loading={isSubmitting}
                          >
                            Create
                          </BaasButton>
                        </BaasGrid>
                      </BaasRow>
                    </BaasGrid>
                  </BaasRow>
                ) : (
                  <BaasTransactionSuccess redirectUrl="/pix/v2/anti-fraud" />
                )}
              </Form>
            )}
          </Formik>
        </BaasContentGrid>
      </BaasFlex>
    </>
  );
};

export default AntiFraudCreate;
