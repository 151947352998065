// ===== Public Routes =====
import ContactUs from "pages/public/ContactUs/ContactUs";
import Documentation from "pages/public/Documentation/Documentation";
import DocumentationRedoc from "pages/public/Documentation/DocumentationRedoc";
import Landing from "pages/public/Landing/Landing";
import Login from "pages/public/Login/Login";
import LoginForgotMyPassword from "pages/public/Login/LoginForgotMyPassword";
import LoginRegister from "pages/public/Login/LoginRegister";
import LoginRegisterNewOtp from "pages/public/Login/LoginRegisterNewOtp";
import NewOtp from "pages/public/NewOtp/NewOtp";
import NewPassword from "pages/public/NewPassword/NewPassword";

// ===== Private Routes =====
import WebhookIcon from "assets/icons/Book.svg";
import DashboardIcon from "assets/icons/Dashboard.svg";
import ExchangeIcon from "assets/icons/Exchange.svg";
import KeyIcon from "assets/icons/Key.svg";
import PayrollIcon from "assets/icons/Payroll.svg";
import PixIcon from "assets/icons/Pix.svg";
import BaasTesterIcon from "assets/icons/BaasTester.svg";
import ReceiptIcon from "assets/icons/Receipt.svg";
import SandboxIcon from "assets/icons/Sandbox.svg";
import ChartsIcon from "assets/icons/Charts.svg";
import OpenFinanceIcon from "assets/icons/OpenFinance.svg";
import CnabInterface from "pages/private/Cnab/CnabInterface/CnabInterface";
import CnabSettlement from "pages/private/Cnab/CnabSettlement/CnabSettlement";
import MovementReport from "pages/private/Cnab/MovementReport/MovementReport";
import Dashboard from "pages/private/Dashboard/Dashboard";
import DashboardExternalTransferCashIn from "pages/private/Dashboard/DashboardExternalTransferCashIn";
import DashboardExternalTransferCashOut from "pages/private/Dashboard/DashboardExternalTransferCashOut";
import DashboardInternalTransfer from "pages/private/Dashboard/DashboardInternalTransfer";
import DashboardPaymentslip from "pages/private/Dashboard/DashboardPaymentslip";
import DashboardPixPaymentCashIn from "pages/private/Dashboard/DashboardPixPaymentCashIn";
import DashboardPixPaymentCashOut from "pages/private/Dashboard/DashboardPixPaymentCashOut";
import EfxInterface from "pages/private/Efx/EfxInterface";
import Claim from "pages/private/Pix/Claim/Claim";
import ClaimCreate from "pages/private/Pix/Claim/ClaimCreate";
import Entry from "pages/private/Pix/Entry/Entry";
import EntryCreate from "pages/private/Pix/Entry/EntryCreate";
import InfractionReport from "pages/private/Pix/InfractionReport/InfractionReport";
import InfractionReportCreate from "pages/private/Pix/InfractionReport/InfractionReportCreate";
import PixCashUrlRegistration from "pages/private/Pix/PixCashUrlRegistration/PixCashUrlRegistration";
import PixTestSuitInfraction from "pages/private/PixTestSuit/PixTestSuitInfraction";
import Refund from "pages/private/Pix/Refund/Refund";
import RefundCreate from "pages/private/Pix/Refund/RefundCreate";
import Receipt from "pages/private/Receipt/Receipt";
import Sandbox from "pages/private/Sandbox/Sandbox";
import UserCredentials from "pages/private/UserCredentials/UserCredentials";
import MtlsCertificate from "pages/private/Webhook/MtlsCertificate/MtlsCertificate";
import WebhookConfig from "pages/private/Webhook/WebhookConfig/WebhookConfig";
import MockStore from "pages/private/MockOBK/MockStore";
import MockStoreBankLogin from "pages/private/MockOBK/MockStoreBankLogin";
import MockBankApproval from "pages/private/MockOBK/MockBankApproval";
import MockBankPooling from "pages/private/MockOBK/MockBankPooling";
import PixTestSuitPayment from "pages/private/PixTestSuit/PixTestSuitPayment";
import PixTestSuitInfractionsList from "pages/private/PixTestSuit/PixTestSuitInfractionsList";
import PixTestSuitInfractionDetails from "pages/private/PixTestSuit/PixTestSuitInfractionDetails";
import PixTestSuitClaimDetails from "pages/private/PixTestSuit/PixTestSuitClaimDetails";
import PixTestSuitClaimsList from "pages/private/PixTestSuit/PixTestSuitClaimsList";
import PixTestSuitClaim from "pages/private/PixTestSuit/PixTestSuitClaim";
import PixTestSuitRefund from "pages/private/PixTestSuit/PixTestSuitRefund";
import PixTestSuitRefundDetails from "pages/private/PixTestSuit/PixTestSuitRefundDetails";
import PixTestSuitRefundsList from "pages/private/PixTestSuit/PixTestSuitRefundsList";
import CustomersManagement from "pages/private/Pix/CustomersManagement/CustomerManagement";
import CreateCustomer from "pages/private/Pix/CustomersManagement/CreateCustomer";
import CustomerDetails from "pages/private/Pix/CustomersManagement/CustomerDetails";
import CustomerEdit from "pages/private/Pix/CustomersManagement/CustomerEdit";
import Monitoring from "pages/private/Monitoring/Monitoring";
import PixTestSuitReversal from "pages/private/PixTestSuit/PixTestSuitReversal";
import OpenFinance from "pages/private/OpenFinance/OpenFinance";
import Endpoints from "constants/endpoints";
import BaasTester from "pages/private/BaasTester/BaasTester";
import QrCodeTester from "pages/private/QrCodeTester/QrCodeTester";
import CustomersManagementV2 from "pages/private/DictV2Pix/CustomersManagement/CustomerManagement";
import InfractionReportV2 from "pages/private/DictV2Pix/InfractionReport/InfractionReport";
import RefundV2 from "pages/private/DictV2Pix/Refund/Refund";
import EntryV2 from "pages/private/DictV2Pix/Entry/Entry";
import ClaimV2 from "pages/private/DictV2Pix/Claim/Claim";
import InfractionReportCreateV2 from "pages/private/DictV2Pix/InfractionReport/InfractionReportCreate";
import RefundCreateV2 from "pages/private/DictV2Pix/Refund/RefundCreate";
import EntryCreateV2 from "pages/private/DictV2Pix/Entry/EntryCreate";
import ClaimCreateV2 from "pages/private/DictV2Pix/Claim/ClaimCreate";
import CustomerDetailsV2 from "pages/private/DictV2Pix/CustomersManagement/CustomerDetails";
import CreateCustomerV2 from "pages/private/DictV2Pix/CustomersManagement/CreateCustomer";
import CustomerEditV2 from "pages/private/DictV2Pix/CustomersManagement/CustomerEdit";
import QrCodePayment from "pages/private/QrCodePayment/QrCodePayment";

import PixWebhookConfig from "pages/private/PixWebhookConfig/PixWebhookConfig";
import AntiFraud from "pages/private/DictV2Pix/AntiFraud/AntiFraud";
import AntiFraudCreate from "pages/private/DictV2Pix/AntiFraud/AntiFraudCreate";
import AntiFraudDetails from "pages/private/DictV2Pix/AntiFraud/AntiFraudDetails";
import PixPaymentTester from "pages/private/PixPaymentTester/PixPaymentTester";
import PixLimitValidatorDashboard from "pages/private/PixLimitValidator/Dashboard/PixLimitValidatorDashboard";
import PixLimitValidatorReportsConfig from "pages/private/PixLimitValidator/ReportConfig/PixLimitValidatorReportsConfig";
import PixLimitValidatorImpacts from "pages/private/PixLimitValidator/Impacts/PixLimitValidatorImpacts";
import PixLimitValidatorReportConfig from "pages/private/PixLimitValidator/ReportDownload/PixLimitValidatorReportDownload";
// import PixLimitValidatorStatement from "pages/private/PixLimitValidator/Statement/PixLimitValidatorStatement";

let routes = [
  {
    path: "/dashboard",
    name: "Dashboard",
    icon: DashboardIcon,
    privateRoute: true,
    component: Dashboard,
    permission: ["Env-Development", "Env-Sandbox", "Env-UAT", "Env-Production"],
    showOnSidebar: true,
  },
  // {
  //   name: "Monitoring",
  //   path: "/monitoring",
  //   component: Monitoring,
  //   icon: ChartsIcon,
  //   permission: ["Env-UAT"], // , "all-w"
  //   privateRoute: true,
  //   showOnSidebar: true,
  // },
  {
    path: "/external-transfer/cash-out",
    name: "External transfer cash out",
    icon: "",
    privateRoute: true,
    component: DashboardExternalTransferCashOut,
    permission: ["Env-Sandbox", "Env-Development", "all-w"],
    showOnSidebar: false,
  },
  {
    path: "/external-transfer/cash-in",
    name: "External transfer cash in",
    icon: "",
    privateRoute: true,
    component: DashboardExternalTransferCashIn,
    permission: ["Env-Sandbox", "Env-Development", "all-w"],
    showOnSidebar: false,
  },
  {
    path: "/internal-transfer",
    name: "Internal transfer",
    icon: "",
    privateRoute: true,
    component: DashboardInternalTransfer,
    permission: ["Env-Sandbox", "Env-Development", "all-w"],
    showOnSidebar: false,
  },
  {
    path: "/payment-slip",
    name: "Payment slip",
    icon: "",
    privateRoute: true,
    component: DashboardPaymentslip,
    permission: ["Env-Sandbox", "Env-Development", "all-w"],
    showOnSidebar: false,
  },
  {
    path: "/pix-payment/cash-in",
    name: "Pix cash in",
    icon: "",
    privateRoute: true,
    component: DashboardPixPaymentCashIn,
    permission: ["Env-Sandbox", "Env-Development", "all-w"],

    showOnSidebar: false,
  },
  {
    path: "/pix-payment/cash-out",
    name: "Pix cash out",
    icon: "",
    privateRoute: true,
    component: DashboardPixPaymentCashOut,
    permission: ["Env-Sandbox", "Env-Development", "all-w"],
    showOnSidebar: false,
  },
  {
    name: "Sandbox",
    icon: SandboxIcon,
    privateRoute: true,
    permission: ["Env-Development", "Env-Sandbox"],
    showOnSidebar: true,
    subRoute: [
      {
        name: "Commom Methods",
        path: "/sandboxcm",
        privateRoute: true,
        permission: ["Env-Development", "Env-Sandbox"],
        component: Sandbox,
      },
      {
        name: "Onboarding",
        path: "/sandboxob",
        privateRoute: true,
        permission: ["Env-Development", "Env-Sandbox"],
        component: Sandbox,
      },
      {
        name: "Transactions",
        path: "/sandboxtr",
        privateRoute: true,
        permission: ["Env-Development", "Env-Sandbox"],
        component: Sandbox,
      },
      {
        name: "Pix Commom",
        path: "/sandboxpc",
        privateRoute: true,
        permission: ["Env-Development", "Env-Sandbox"],
        component: Sandbox,
      },
      {
        name: "PIX Indirect Participant",
        path: "/sandboxpi",
        privateRoute: true,
        permission: ["Env-Development", "Env-Sandbox"],
        component: Sandbox,
      },
      {
        name: "PIX Merchant Clients",
        path: "/sandboxpm",
        privateRoute: true,
        permission: ["Env-Development", "Env-Sandbox"],
        component: Sandbox,
      },
    ],
  },
  {
    name: "Webhook",
    icon: WebhookIcon,
    privateRoute: true,
    permission: ["webhook-adm", "webhook-r", "webhook-w", "all-w", "all-r"],
    showOnSidebar: true,
    subRoute: [
      {
        name: "Webhook Config",
        path: "/webhook",
        privateRoute: true,
        permission: ["webhook-adm", "webhook-r", "webhook-w", "all-w", "all-r"],
        component: WebhookConfig,
      },
      {
        name: "PIX Key Webhook",
        path: "/pix-key-webhook",
        privateRoute: true,
        permission: [
          "pix-merch-w",
          "pix-merch-r",
          "all-w",
          "all-r",
          "Env-UAT",
          "Env-Production",
          // "Env-Sandbox",
        ],
        component: PixWebhookConfig,
      },
    ],
  },
  {
    name: "CNAB",
    icon: PayrollIcon,
    privateRoute: true,
    permission: ["Env-UAT", "Env-Production", "pay-slip-cnab-w"],
    showOnSidebar: true,
    subRoute: [
      {
        name: "CNAB Interface",
        path: "/cnab-interface",
        privateRoute: true,
        permission: ["Env-UAT", "Env-Production", "pay-slip-cnab-w"],
        component: CnabInterface,
      },
      {
        name: "CNAB Settlement",
        path: "/cnab-settlement",
        privateRoute: true,
        permission: ["Env-UAT", "Env-Production", "pay-slip-cnab-w"],
        component: CnabSettlement,
      },
      {
        name: "CNAB Movement Report",
        path: "/cnab-movement-report",
        privateRoute: true,
        permission: ["Env-UAT", "Env-Production", "pay-slip-cnab-w"],
        component: MovementReport,
      },
    ],
  },
  {
    name: "eFX Services",
    path: "/efxservice",
    icon: ExchangeIcon,
    component: EfxInterface,
    privateRoute: true,
    permission: [
      "Env-UAT",
      // "Env-Production",
      "efx-r",
      "efx-w",
      "all-w",
    ],
    showOnSidebar: true,
  },
  {
    name: "User Credentials",
    icon: KeyIcon,
    privateRoute: true,
    permission: ["user-r", "all-w", "all-r"],
    showOnSidebar: true,
    subRoute: [
      {
        name: "User Info",
        path: "/user-credentials",
        privateRoute: true,
        permission: ["user-r", "all-w", "all-r"],
        component: UserCredentials,
      },
      {
        name: "mTLS Certificate",
        path: "/mtls-certificate",
        privateRoute: true,
        permission: ["Env-UAT", "Env-Production", "user-key-w", "all-w"],
        component: MtlsCertificate,
      },
    ],
  },
  {
    name: "Receipt",
    path: "/receipt",
    icon: ReceiptIcon,
    component: Receipt,
    privateRoute: true,
    permission: ["Env-UAT", "Env-Production", "receipt-r", "all-w"],
    showOnSidebar: true,
  },
  {
    name: "Pix Infraction",
    path: "/pix-test-suit-infraction/:txId",
    component: PixTestSuitInfraction,
    privateRoute: true,
    showOnSidebar: false,
    permission: ["Env-Sandbox"],
  },
  {
    name: "Create Infraction",
    path: "/pix-test-suit-infraction",
    component: PixTestSuitInfraction,
    showOnSidebar: false,
    privateRoute: true,
    permission: ["Env-Sandbox"],
  },
  {
    name: "Infraction Details",
    path: "/pix-test-suit-infraction-details/:infractionId",
    component: PixTestSuitInfractionDetails,
    showOnSidebar: false,
    privateRoute: true,
    permission: ["Env-Sandbox"],
  },
  {
    name: "Claim Details",
    path: "/pix-test-suit-claim-details/:claimId",
    component: PixTestSuitClaimDetails,
    showOnSidebar: false,
    privateRoute: true,
    permission: ["Env-Sandbox"],
  },

  {
    name: "Create Refund",
    path: "/pix-test-suit-refund-create/:infractionId/:endToEndId",
    component: PixTestSuitRefund,
    showOnSidebar: false,
    privateRoute: true,
    permission: ["Env-Sandbox"],
  },
  {
    name: "Create Refund",
    path: "/pix-test-suit-refund-create",
    component: PixTestSuitRefund,
    showOnSidebar: false,
    privateRoute: true,
    permission: ["Env-Sandbox"],
  },
  {
    name: "Refund Details",
    path: "/pix-test-suit-refund-details/:refundId",
    component: PixTestSuitRefundDetails,
    showOnSidebar: false,
    privateRoute: true,
    permission: ["Env-Sandbox"],
  },
  {
    name: "Create Claim",
    path: "/pix-test-suit-claim",
    component: PixTestSuitClaim,
    showOnSidebar: false,
    privateRoute: true,
    permission: ["Env-Sandbox"],
  },
  {
    name: "Customer Details",
    path: "/pix/customers-management-details/:customerId",
    component: CustomerDetails,
    showOnSidebar: false,
    privateRoute: true,
    permission: [
      "Env-Sandbox",
      "Env-UAT",
      "Env-Production",
      "dict-hub-w",
      "dict-hub-r",
      "all-w",
    ],
  },
  {
    name: "Create Customer",
    path: "/pix/customers-management-create",
    component: CreateCustomer,
    showOnSidebar: false,
    privateRoute: true,
    permission: [
      "Env-Sandbox",
      "Env-UAT",
      "Env-Production",
      "dict-hub-w",
      "dict-hub-r",
      "all-w",
    ],
  },
  {
    name: "Edit Customer",
    path: "/pix/customers-management-edit/:customerId",
    component: CustomerEdit,
    showOnSidebar: false,
    privateRoute: true,
    permission: [
      "Env-UAT",
      "Env-Production",
      "dict-hub-w",
      "dict-hub-r",
      "Env-Sandbox",
      "all-w",
    ],
  },
  {
    name: "Pix Config",
    icon: PixIcon,
    privateRoute: true,
    permission: [
      "Env-Development",
      "Env-UAT",
      "Env-Production",
      "Env-Sandbox",
      "pix-pay-w",
      "all-w",
    ],
    showOnSidebar: true,
    subRoute: [
      {
        name: "Pix cash in URL registration",
        path: "/cash-in-pix",
        privateRoute: true,
        component: PixCashUrlRegistration,
        permission: [
          "Env-Development",
          "Env-UAT",
          "Env-Production",
          "Env-Sandbox",
          "pix-pay-w",
          "all-w",
        ],
      },
    ],
  },
  {
    name: "Pix Sandbox",
    icon: PixIcon,
    privateRoute: true,
    permission: ["Env-Development", "Env-Sandbox", "pix-sandbox-v"],
    showOnSidebar: true,
    subRoute: [
      {
        name: "QR Code payment",
        path: "/pix-test-suit",
        component: PixTestSuitPayment,
        privateRoute: true,
        permission: ["Env-Development", "Env-Sandbox", "pix-sandbox-v"],
      },
      {
        name: "Infraction",
        path: "/pix-test-suit-infractions-list",
        component: PixTestSuitInfractionsList,
        privateRoute: true,
        permission: ["Env-Development", "Env-Sandbox", "pix-sandbox-v"],
      },
      {
        name: "Refund",
        path: "/pix-test-suit-refund",
        component: PixTestSuitRefundsList,
        privateRoute: true,
        permission: ["Env-Development", "Env-Sandbox", "pix-sandbox-v"],
      },
      {
        name: "Reversal In",
        path: "/pix-test-suit-reversal",
        component: PixTestSuitReversal,
        privateRoute: true,
        permission: ["Env-Development", "Env-Sandbox", "pix-sandbox-v"],
      },
      {
        name: "Claim",
        path: "/pix-test-suit-claims-list",
        component: PixTestSuitClaimsList,
        privateRoute: true,
        permission: ["Env-Development", "Env-Sandbox", "pix-sandbox-v"],
      },
    ],
  },
  {
    name: "Pix Limit Validator",
    icon: PixIcon,
    privateRoute: true,
    permission: [
      "Env-Development",
      "Env-UAT",
      "Env-Production",
      "pix-limit-valid-v2-w",
      "pix-limit-valid-v2-r",
    ],
    showOnSidebar: true,
    subRoute: [
      {
        name: "Dashboard",
        path: "/pix-limit-validator-dashboard",
        component: PixLimitValidatorDashboard,
        privateRoute: true,
        permission: [
          "Env-Development",
          "Env-UAT",
          "Env-Production",
          "pix-limit-valid-v2-w",
          "pix-limit-valid-v2-r",
        ],
      },
      {
        name: "Pix Impacts",
        path: "/pix-limit-validator-impacts",
        component: PixLimitValidatorImpacts,
        privateRoute: true,
        permission: [
          "Env-Development",
          "Env-UAT",
          "Env-Production",
          "pix-limit-valid-v2-w",
          "pix-limit-valid-v2-r",
        ],
      },
      {
        name: "Infraction",
        path: "/pix-limit-validator-infraction",
        component: InfractionReportV2,
        privateRoute: true,
        permission: [
          "Env-Development",
          "Env-UAT",
          "Env-Production",
          "pix-limit-valid-v2-w",
          "pix-limit-valid-v2-r",
        ],
      },
      {
        name: "Report Config",
        path: "/pix-limit-validator-report-config",
        component: PixLimitValidatorReportsConfig,
        privateRoute: true,
        permission: [
          "Env-Development",
          "Env-UAT",
          "Env-Production",
          "pix-limit-valid-v2-w",
          "pix-limit-valid-v2-r",
        ],
      },
      {
        name: "Report Download",
        path: "/pix-limit-validator-report-download",
        component: PixLimitValidatorReportConfig,
        privateRoute: true,
        permission: [
          "Env-Development",
          "Env-UAT",
          "Env-Production",
          "pix-limit-valid-v2-w",
          "pix-limit-valid-v2-r",
        ],
      },
    ],
  },
  {
    name: "Test Interface",
    icon: BaasTesterIcon,
    privateRoute: true,
    permission: ["Env-Development", "Env-UAT", "baas-tester-w", "all-w"],
    showOnSidebar: true,
    subRoute: [
      {
        name: "Test Control Suit",
        path: "/baas-tester-control-suit",
        component: BaasTester,
        privateRoute: true,
        permission: ["Env-Development", "Env-UAT", "baas-tester-w", "all-w"],
      },
      {
        name: "QR Code Tester",
        path: "/baas-tester-qr-tester",
        component: QrCodeTester,
        privateRoute: true,
        permission: ["Env-Development", "Env-UAT", "baas-tester-w", "all-w"],
      },
      {
        name: "QR Code Payment",
        path: "/baas-tester-qr-payment",
        component: QrCodePayment,
        privateRoute: true,
        permission: ["Env-Development", "Env-UAT", "baas-tester-w", "all-w"],
      },
      {
        name: "Pix Payment Tester",
        path: "/baas-tester-pix-payment-tester",
        component: PixPaymentTester,
        privateRoute: true,
        permission: ["Env-Development", "Env-UAT", "baas-tester-w", "all-w"],
      },
    ],
  },
  {
    name: "DICT Hub",
    icon: PixIcon,
    privateRoute: true,
    permission: [
      "Env-Sandbox",
      "Env-UAT",
      "Env-Production",
      "pix-merch-w",
      "dict-hub-r",
      "dict-hub-w",
      Endpoints.Environment !== "Sandbox" ? "all-w" : "pix-dict-hub-sandbox-v",
    ],
    showOnSidebar: true,
    subRoute: [
      {
        name: "Infraction Report",
        path: "/pix/v2/infraction-report",
        component: InfractionReportV2,
        privateRoute: true,
        permission: [
          "Env-Sandbox",
          "Env-UAT",
          "Env-Production",
          "pix-merch-w",
          "dict-hub-w",
          "dict-hub-r",
          Endpoints.Environment !== "Sandbox"
            ? "all-w"
            : "pix-dict-hub-sandbox-v",
        ],
      },
      {
        name: "Refund (MED)",
        path: "/pix/v2/refund",
        component: RefundV2,
        privateRoute: true,
        permission: [
          "Env-Sandbox",
          "Env-UAT",
          "Env-Production",
          "pix-merch-w",
          "dict-hub-w",
          "dict-hub-r",
          Endpoints.Environment !== "Sandbox"
            ? "all-w"
            : "pix-dict-hub-sandbox-v",
        ],
      },
      {
        name: "DICT Entry",
        path: "/pix/v2/entry",
        component: EntryV2,
        privateRoute: true,
        permission: [
          "Env-Sandbox",
          "Env-UAT",
          "Env-Production",
          "dict-hub-w",
          "dict-hub-r",
          Endpoints.Environment !== "Sandbox"
            ? "all-w"
            : "pix-dict-hub-sandbox-v",
        ],
      },
      {
        name: "DICT Claim",
        path: "/pix/v2/dict-claim",
        component: ClaimV2,
        privateRoute: true,
        permission: [
          "Env-Sandbox",
          "Env-UAT",
          "Env-Production",
          "dict-hub-w",
          "dict-hub-r",
          Endpoints.Environment !== "Sandbox"
            ? "all-w"
            : "pix-dict-hub-sandbox-v",
        ],
      },
      {
        name: "Anti Fraud",
        path: "/pix/v2/anti-fraud",
        component: AntiFraud,
        privateRoute: true,
        permission: [
          "Env-Sandbox",
          "Env-UAT",
          "Env-Production",
          "dict-hub-w",
          "dict-hub-r",
          "all-w",
        ],
      },
    ],
  },
  {
    path: "/pix/v2/anti-fraud/create",
    name: "Create New Anti Fraud Marker",
    icon: "",
    privateRoute: true,
    component: AntiFraudCreate,
    permission: [
      "Env-Sandbox",
      "Env-UAT",
      "Env-Production",
      "dict-hub-w",
      "all-w",
    ],
    showOnSidebar: false,
  },
  {
    path: "/pix/v2/anti-fraud/details/:fraudMarkerId",
    name: "Anti Fraud Marker Details",
    icon: "",
    privateRoute: true,
    component: AntiFraudDetails,
    permission: [
      "Env-Sandbox",
      "Env-UAT",
      "Env-Production",
      "dict-hub-w",
      "dict-hub-r",
      "all-w",
    ],
    showOnSidebar: false,
  },
  //DICT VERSION 2
  {
    path: "/pix/v2/infraction-report/create",
    name: "Create New Infraction Report",
    icon: "",
    privateRoute: true,
    component: InfractionReportCreateV2,
    permission: [
      "Env-Sandbox",
      "Env-UAT",
      "Env-Production",
      "pix-merch-w",
      "dict-hub-w",
      "all-w",
    ],
    showOnSidebar: false,
  },
  {
    path: "/pix/v2/refund/create",
    name: "Create New Refund (MED)",
    icon: "",
    privateRoute: true,
    component: RefundCreateV2,
    permission: [
      "Env-Sandbox",
      "Env-UAT",
      "Env-Production",
      "dict-hub-w",
      "all-w",
    ],
    showOnSidebar: false,
  },
  {
    path: "/pix/v2/entry/create",
    name: "Create New DICT Entry",
    icon: "",
    privateRoute: true,
    component: EntryCreateV2,
    permission: [
      "Env-Sandbox",
      "Env-UAT",
      "Env-Production",
      "dict-hub-w",
      "all-w",
    ],
    showOnSidebar: false,
  },
  {
    path: "/pix/v2/dict-claim/create",
    name: "Create New DICT Claim",
    icon: "",
    privateRoute: true,
    component: ClaimCreateV2,
    permission: [
      "Env-Sandbox",
      "Env-UAT",
      "Env-Production",
      "dict-hub-w",
      "all-w",
    ],
    showOnSidebar: false,
  },
  // {
  //   name: "Customer Details",
  //   path: "/pix/v2/customers-management-details/:customerId",
  //   component: CustomerDetailsV2,
  //   showOnSidebar: false,
  //   privateRoute: true,
  //   permission: [
  //     "Env-Sandbox",
  //     "Env-UAT",
  //     "Env-Production",
  //     "dict-hub-w",
  //     "dict-hub-r",
  //     "all-w",
  //   ],
  // },
  // {
  //   name: "Create Customer",
  //   path: "/pix/v2/customers-management-create",
  //   component: CreateCustomerV2,
  //   showOnSidebar: false,
  //   privateRoute: true,
  //   permission: [
  //     "Env-Sandbox",
  //     "Env-UAT",
  //     "Env-Production",
  //     "dict-hub-w",
  //     "dict-hub-r",
  //     "all-w",
  //   ],
  // },
  // {
  //   name: "Edit Customer",
  //   path: "/pix/v2/customers-management-edit/:customerId",
  //   component: CustomerEditV2,
  //   showOnSidebar: false,
  //   privateRoute: true,
  //   permission: [
  //     "Env-UAT",
  //     "Env-Production",
  //     "dict-hub-w",
  //     "dict-hub-r",
  //     "Env-Sandbox",
  //     "all-w",
  //   ],
  // },
  //END OF DICT VERSION 2
  {
    path: "/dochub",
    name: "DocHub",
    privateRoute: false,
    component: Documentation,
    permission: [],
    showOnSidebar: false,
  },
  {
    path: "/doccm",
    name: "DocCm",
    component: DocumentationRedoc,
    privateRoute: false,
    permission: [],
    showOnSidebar: false,
  },
  {
    path: "/docob",
    name: "DocOb",
    component: DocumentationRedoc,
    privateRoute: false,
    permission: [],
    showOnSidebar: false,
  },
  {
    path: "/docpc",
    name: "Docpc",
    component: DocumentationRedoc,
    privateRoute: false,
    permission: [],
    showOnSidebar: false,
  },
  {
    path: "/docpi",
    name: "Docpi",
    component: DocumentationRedoc,
    privateRoute: false,
    permission: [],
    showOnSidebar: false,
  },
  {
    path: "/docpm",
    name: "Docpm",
    component: DocumentationRedoc,
    privateRoute: false,
    permission: [],
    showOnSidebar: false,
  },
  {
    path: "/doctr",
    name: "DocTr",
    component: DocumentationRedoc,
    privateRoute: false,
    permission: [],
    showOnSidebar: false,
  },
  {
    path: "/docbk",
    name: "DocBk",
    component: DocumentationRedoc,
    privateRoute: false,
    permission: [],
    showOnSidebar: false,
  },
  {
    path: "/docld",
    name: "Docld",
    component: DocumentationRedoc,
    privateRoute: false,
    permission: [],
    showOnSidebar: false,
  },
  {
    path: "/docda",
    name: "Docda",
    component: DocumentationRedoc,
    privateRoute: false,
    permission: [],
    showOnSidebar: false,
  },
  {
    path: "/errorReport",
    name: "Error Report",
    icon: "",
    privateRoute: false,
    permission: [],
    showOnSidebar: false,
  },
  {
    path: "/landingpage",
    name: "LandingPage",
    icon: "",
    component: Landing,
    privateRoute: false,
    permission: [],
    showOnSidebar: false,
  },
  {
    path: "/onboarding",
    name: "OnBoarding",
    icon: "",
    component: LoginRegister,
    privateRoute: false,
    permission: [],
    showOnSidebar: false,
  },
  {
    path: "/confirmation",
    name: "Confirmation",
    icon: "",
    privateRoute: false,
    permission: [],
    showOnSidebar: false,
  },
  {
    path: "/login",
    name: "Login",
    icon: "",
    component: Login,
    privateRoute: false,
    permission: [],
    showOnSidebar: false,
  },
  {
    path: "/faq",
    name: "Faq",
    component: ContactUs,
    privateRoute: false,
    permission: [],
    showOnSidebar: false,
  },
  {
    path: "/newpassword",
    name: "Password Change",
    icon: "",
    component: NewPassword,
    privateRoute: false,
    permission: [],
    showOnSidebar: false,
  },
  {
    path: "/forget-password",
    name: "Forget Password",
    icon: "",
    component: LoginForgotMyPassword,
    privateRoute: false,
    permission: [],
    showOnSidebar: false,
  },
  {
    path: "/register-new-otp",
    name: "Register new OTP",
    icon: "",
    component: LoginRegisterNewOtp,
    privateRoute: false,
    permission: [],
    showOnSidebar: false,
  },
  {
    path: "/mfa",
    name: "New OTP",
    icon: "",
    component: NewOtp,
    privateRoute: false,
    permission: [],
    showOnSidebar: false,
  },
  {
    name: "Open Finance",
    permission: ["Env-UAT", "all-w", "obk-mock-w"],
    icon: OpenFinanceIcon,
    privateRoute: true,
    showOnSidebar: true,
    subRoute: [
      {
        name: "Payment Initiation",
        path: "/mock-obk/mock-store",
        privateRoute: true,
        permission: ["Env-UAT", "all-w", "obk-mock-w"],
        component: MockStore,
      },
      {
        name: "Payment Initiation (JWR)",
        path: "/openFinance",
        component: OpenFinance,
        permission: ["Env-UAT", "all-w", "obk-mock-w"],
        privateRoute: true,
      },
    ],
  },
  {
    name: "Mock-obk",
    path: "/mock-obk/mock-bank",
    component: MockStoreBankLogin,
    permission: ["Env-UAT", "all-w", "obk-mock-w"],
    privateRoute: true,
    showOnSidebar: false,
  },
  {
    name: "Mock-obk",
    path: "/mock-obk/login",
    component: MockBankApproval,
    permission: ["Env-UAT", "all-w", "obk-mock-w"],
    privateRoute: true,
    showOnSidebar: false,
  },
  {
    name: "Mock-obk",
    path: "/mock-obk/TransactionConfirmed/:pactualId/:status",
    component: MockBankPooling,
    permission: ["Env-UAT", "all-w", "obk-mock-w"],
    privateRoute: true,
    showOnSidebar: false,
  },
];

export { routes };
