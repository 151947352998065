import { Box } from "@mui/material";
import { useTheme } from "@mui/system";
import { queryGetPixImpactsByTaxId } from "api/pixLimitValidator/pixLimitValidator";
import { useQueryGetUserInfo } from "api/security-api";
import BaasButton from "components/atoms/Button/Button";
import BaasDatePicker from "components/atoms/DatePicker/DatePicker";
import BaasFlex from "components/atoms/Flex/Flex";
import BaasGrid from "components/atoms/Grid/Grid";
import BaasMenuItem from "components/atoms/MenuItem/MenuItem";
import BaasText from "components/atoms/Text/BaasText";
import BaasTextField from "components/atoms/TextField/TextField";
import AccountTypeSelectedLabel from "components/molecules/AccountTypeSelectedLabel/AccountTypeSelectedLabel";
import BaasPageHeader from "components/molecules/PageHeader/PageHeader";
import BaasContentGrid from "components/templates/ContentGrid/ContentGrid";
import { toastMessages } from "constants/toastMessages";
import { Form, Formik } from "formik";
import _ from "lodash";
import moment from "moment";
import React, { useCallback, useState } from "react";
import { toast } from "react-toastify";
import PixLimitValidatorImpactsDetails from "./PixLimitValidatorImpactDetails";
import PixLimitValidatorImpactsList from "./PixLimitValidatorImpactsList";

const PixLimitValidatorImpacts = () => {
  const theme = useTheme();
  const [selectedItem, setSelectedItem] = useState();

  const [loading, setLoading] = useState(false);
  const { data: clientData } = useQueryGetUserInfo();
  const [pagination, setPagination] = useState();
  const [queryVariables, setQueryVariables] = useState();
  const [paginatedData, setPaginatedData] = useState([]);

  const handleSearch = useCallback(async (values, oldData) => {
    if (!oldData) {
      setLoading(true);
    }

    const response = await queryGetPixImpactsByTaxId(values);
    if (response.status < 300) {
      handleAppendData(response, oldData);
    } else {
      if (response.status === 404) {
        setPaginatedData([]);
        setPagination({
          ...pagination,
          hasMoreElements: false,
        });
        toast.warning(toastMessages.search.searchEmpty);
      }
      if ("errors" in response) {
        response.errors.map((error) => {
          return toast.error(error.message);
        });
        setPaginatedData([]);
      }
    }
    setLoading(false);
  }, []);

  const handleAppendData = (response, oldData) => {
    const formatedList = formatList(response.body.items);
    if (formatedList === 0) toast.warning(toastMessages.search.searchEmpty);
    if (oldData) {
      setPagination({
        hasMoreElements: response.body?.hasMoreElements,
        lastEvaluatedKey: response.body?.lastEvaluatedKey,
      });
      setPaginatedData(
        oldData
          ? _.uniqBy([...oldData, ...formatedList], "pactualId")
          : formatedList
      );
    } else {
      setPagination({
        hasMoreElements: response.body?.hasMoreElements,
        lastEvaluatedKey: response.body?.lastEvaluatedKey,
      });
      setPaginatedData(formatedList);
    }
  };

  const formatList = (data) => {
    let list = [];

    const qrCodeList = (data?.qrcode || []).map((item) => {
      return {
        ...item,
        entity: "QR Code",
      };
    });
    const cashInList = (data?.cashIn || []).map((item) => {
      return {
        ...item,
        entity: "Cash In",
      };
    });
    const cashOutList = (data?.cashOut || []).map((item) => {
      return {
        ...item,
        entity: "Cash Out",
      };
    });

    list = [...qrCodeList, ...cashInList, ...cashOutList];
    return list;
  };

  const onSearch = useCallback(
    (values) => {
      if (!clientData.clientId) {
        return toast.error("An error ocurred, Client Id is unavailable.");
      }
      const params = {
        startDate: `${moment(values.startDate).format("YYYY-MM-DD")}T00:00:00Z`,
        endDate: `${moment(values.endDate).format("YYYY-MM-DD")}T23:59:59Z`,
        entity: values.entities,
        limit: 50,
      };
      const queryVariables = {
        clientId: clientData.clientId,
        taxId: values.taxId,
        params: params,
      };
      setQueryVariables(queryVariables);
      handleSearch(queryVariables);
    },
    [clientData?.clientId, handleSearch]
  );

  const handleLoadMore = () => {
    const queryPaginatedVariables = {
      ...queryVariables,
      params: {
        ...queryVariables.params,
        lastEvaluatedKey: pagination.lastEvaluatedKey,
      },
    };
    setQueryVariables(queryPaginatedVariables);
    handleSearch(queryPaginatedVariables, paginatedData);
  };

  const handleUpdateEntitiesSelect = (event, setFieldValue, values) => {
    setFieldValue("entities", event.target.value);
  };

  const onBack = () => {
    setSelectedItem(false);
  };

  // if (selectedItem) {
  //   return (

  //   );
  // }

  return (
    <>
      <Box flexDirection="column" width="100%">
        <BaasPageHeader>Pix Limit Validator</BaasPageHeader>
        <BaasContentGrid spacing={4}>
          <BaasGrid p="16px 0px" item>
            <BaasGrid display="flex">
              <BaasText variant="h6">
                {selectedItem
                  ? "Transaction Details"
                  : "Pix Limit Validator Impacts"}
              </BaasText>
              <AccountTypeSelectedLabel />
            </BaasGrid>
          </BaasGrid>

          {selectedItem && (
            <PixLimitValidatorImpactsDetails
              selectedItem={selectedItem}
              onBack={onBack}
            />
          )}

          <Box style={{ visibility: selectedItem ? "hidden" : "visible" }}>
            <Formik
              initialValues={{
                clientId: "",
                entities: [],
              }}
              onSubmit={onSearch}
              enableReinitialize
            >
              {({ values, setFieldValue, ...props }) => {
                return (
                  <Form>
                    <BaasGrid
                      p="16px"
                      sx={{ background: theme.palette.grey[100] }}
                    >
                      <BaasText>
                        Search for customers transactions by passing the
                        customer's TaxId.
                      </BaasText>
                      <BaasGrid container spacing={3}>
                        <BaasGrid item xs={12} sm={6}>
                          <BaasTextField
                            fullWidth
                            name="taxId"
                            label="Tax Id"
                            type="text"
                            required
                          />
                        </BaasGrid>
                        <BaasGrid item xs={12} md={6}>
                          <BaasTextField
                            fullWidth
                            name="entities"
                            label="Entities"
                            required
                            SelectProps={{
                              multiple: true,
                              value: values.entities,
                              onChange: (e) =>
                                handleUpdateEntitiesSelect(
                                  e,
                                  setFieldValue,
                                  values
                                ),
                            }}
                            select
                          >
                            <BaasMenuItem value="QRCODE">QR Code</BaasMenuItem>
                            <BaasMenuItem value="CASHIN">Cash In</BaasMenuItem>
                            <BaasMenuItem value="CASHOUT">
                              Cash Out
                            </BaasMenuItem>
                          </BaasTextField>
                        </BaasGrid>
                        <BaasGrid item xs={12} sm={6}>
                          <BaasDatePicker
                            name="startDate"
                            label="Start Date"
                            fullWidth
                          />
                        </BaasGrid>

                        <BaasGrid item xs={12} sm={6}>
                          <BaasDatePicker
                            name="endDate"
                            label="End Date"
                            required
                            fullWidth
                          />
                        </BaasGrid>
                      </BaasGrid>

                      <BaasGrid item p="16px 0px">
                        <BaasButton
                          variant="contained"
                          backgroundColor="black"
                          loading={false}
                          type="submit"
                        >
                          Search
                        </BaasButton>
                      </BaasGrid>
                    </BaasGrid>
                  </Form>
                );
              }}
            </Formik>
            <PixLimitValidatorImpactsList
              data={paginatedData}
              setSelectedItem={setSelectedItem}
              loading={loading}
              loadMore={handleLoadMore}
              pagination={pagination}
            />
          </Box>
        </BaasContentGrid>
      </Box>
    </>
  );
};
export default PixLimitValidatorImpacts;
