import Backdrop from "@mui/material/Backdrop";
import { useTheme } from "@mui/private-theming";
import { Box } from "@mui/system";
import { mutationPostPixDictByRefundIdClose } from "api/pix-api";
import BaasButton from "components/atoms/Button/Button";
import BaasGrid from "components/atoms/Grid/Grid";
import BaasMenuItem from "components/atoms/MenuItem/MenuItem";
import BaasModal from "components/atoms/Modal/Modal";
import BaasText from "components/atoms/Text/BaasText";
import BaasTextField from "components/atoms/TextField/TextField";
import BaasOtpModal from "components/organism/OtpModal/OtpModal";
import { toastMessages } from "constants/toastMessages";
import { Form, Formik } from "formik";
import { useAccount } from "providers/account";
import { useOtp } from "providers/oneTimePassword";
import React, { useCallback, useEffect, useState } from "react";
import ReactJson from "react-json-view";
import { toast } from "react-toastify";
import styled from "styled-components";
import Endpoint from "constants/endpoints";
import { queryDictHubRefundRoutesSandbox } from "api/pix-api-sandbox";
import { useCustomQuery } from "helpers/useCustomQuery";
import BaasSkeleton from "components/atoms/Skeleton/Skeleton";
import BaasBox from "components/atoms/Box/Box";
import DinamicForm from "components/organism/DinamicForm/DinamicForm";

const BoxStyled = styled(Box)`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  min-width: 400px;
  max-width: 600px;

  max-height: 75vh;
  background-color: ${(props) => props.theme.palette.grey[200]};

  box-shadow: 24;
  padding: 4px;
  border-radius: 4px;
  overflow: hidden;
  overflow-y: scroll;

  :focus-visible {
    outline: none;
  }
`;

const JsonStyled = styled(Box)`
  border-radius: 4px;
  background: ${(props) => props.theme.palette.grey[100]};
  max-height: 400px;
  overflow-y: scroll;
  border-radius: 4px;
  padding: 8px;
`;

const RefundSearchListModal = ({
  data,
  loadingSearch,
  setModal,
  modal,
  ispb,
  handleRefetch,
}) => {
  const theme = useTheme();
  const { setOpen } = useOtp();
  const { accountType } = useAccount();
  const [loadingGeneric, setLoadingGeneric] = useState();
  const [loadingAsyncRefetch, setLoadingAsyncRefetch] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const handleClose = () => {
    setModal({ state: false });
  };

  const isSandboxEnvironment = Endpoint.Environment === "Sandbox";
  const filteredData = data.find((item) => item.id === modal.id);

  const {
    fetch: fetchRoutes,
    data: routesData,
    loading: loadingRoutes,
  } = useCustomQuery(queryDictHubRefundRoutesSandbox);

  useEffect(() => {
    if (isSandboxEnvironment && filteredData) {
      fetchRoutes({
        values: filteredData?.id,
        objectFallback: [],
        onNotFoundError: () => {},
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetchRoutes, filteredData?.id]);

  const mutateClose = useCallback(async (values) => {
    const response = await mutationPostPixDictByRefundIdClose(values);
    if (response.status < 300) {
      toast.success(toastMessages.requestSuccess);
      setOpen(false);
      handleClose();
    } else {
      if ("errors" in response) {
        response.errors.map((error) => {
          return toast.error(error.message);
        });
      }
    }
    setIsSubmitting(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onSubmit = useCallback(
    (values, formik) => {
      setIsSubmitting(true);
      mutateClose({
        otp: values.otp,
        refundId: filteredData.id,
        refundRejectionReason: values.refundRejectionReason,
        refundAnalysisDetails: values.refundAnalysisDetails,
        refundTransactionId: values.refundTransactionId,
        refundAnalysisResult: values.refundAnalysisResult,
        accountType: accountType,
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [setOpen, mutateClose, filteredData]
  );

  const errorMessage = () => {
    if (filteredData.status === "CANCELLED") {
      return "Refund cancelled, no further actions needed.";
    } else if (filteredData.status === "CLOSED") {
      return "Refund closed, no further actions needed.";
    } else if (
      filteredData.requestingParticipant === ispb ||
      filteredData.status !== "OPEN"
    ) {
      return "Close Option unavailable, your client requested this refund.";
    }
  };

  const handleRefetchRoutes = () => {
    setLoadingAsyncRefetch(true);
    handleRefetch();
    setTimeout(() => {
      fetchRoutes({
        values: filteredData?.id,
        objectFallback: [],
        onNotFoundError: () => {},
      });
      setLoadingAsyncRefetch(false);
    }, 10000);
  };

  return (
    <>
      <BaasModal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={modal.state}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        {isSandboxEnvironment ? (
          <BoxStyled p={3} theme={theme} sx={{ maxWidth: "400px" }}>
            <BaasText>Refund Details (Object TreeView)</BaasText>
            <Box mb={2} mt={2}>
              <BaasText variant="body2" fontWeight="bold">
                Possible Actions
              </BaasText>
              <BaasText
                style={{
                  color: "#fa2",
                }}
                mt={1}
              >
                Note that the status update and possible actions will only occur
                after one minute.
              </BaasText>
              {loadingRoutes ? (
                <BaasSkeleton width="100%" height="65px" />
              ) : routesData && routesData.length > 0 ? (
                routesData.map((route) => (
                  <BaasBox
                    sx={{ borderTop: "1px solid #CACACA" }}
                    mt={2}
                    key={route.message}
                  >
                    <DinamicForm
                      loading={
                        loadingGeneric || loadingAsyncRefetch || loadingSearch
                      }
                      setLoading={setLoadingGeneric}
                      onComplete={handleRefetchRoutes}
                      route={route}
                    />
                  </BaasBox>
                ))
              ) : (
                <>
                  <BaasText color="error" mt={1}>
                    There is no actions available to this refund.
                  </BaasText>
                </>
              )}
            </Box>
            <JsonStyled theme={theme}>
              <ReactJson
                src={filteredData}
                style={{ height: "100%", overflowY: "scroll" }}
                displayObjectSize={false}
                displayDataTypes={false}
              />
            </JsonStyled>
          </BoxStyled>
        ) : (
          <Formik initialValues={{}} onSubmit={() => setOpen(true)}>
            {({ values, ...props }) => (
              <Form>
                <BaasOtpModal
                  onConfirm={() => onSubmit(values, props)}
                  {...props}
                />
                <BoxStyled p={3} theme={theme} sx={{ maxWidth: "400px" }}>
                  <BaasText>Refund Details (Object TreeView)</BaasText>

                  <BaasGrid container spacing={1} p="16px 0px">
                    <BaasGrid item xs={12}>
                      <BaasTextField
                        fullWidth
                        required
                        name="refundAnalysisResult"
                        disabled={
                          filteredData.requestingParticipant === ispb ||
                          filteredData.status !== "OPEN"
                        }
                        label="Refund analysis result"
                        select
                      >
                        <BaasMenuItem value="TOTALLY_ACCEPTED">
                          Totally accepted
                        </BaasMenuItem>
                        <BaasMenuItem value="PARTIALLY_ACCEPTED">
                          Partially accepted
                        </BaasMenuItem>
                        <BaasMenuItem value="REJECTED">Rejected</BaasMenuItem>
                      </BaasTextField>
                    </BaasGrid>

                    <BaasGrid item xs={12}>
                      <BaasTextField
                        fullWidth
                        name="refundAnalysisDetails"
                        disabled={
                          filteredData.requestingParticipant === ispb ||
                          filteredData.status !== "OPEN"
                        }
                        label="Refund analysis details"
                      />
                    </BaasGrid>

                    <BaasGrid item xs={12}>
                      <BaasTextField
                        fullWidth
                        name="refundTransactionId"
                        disabled={
                          filteredData.requestingParticipant === ispb ||
                          filteredData.status !== "OPEN"
                        }
                        label="Refund transaction Id"
                      />
                    </BaasGrid>

                    <BaasGrid item xs={12}>
                      <BaasTextField
                        fullWidth
                        name="refundRejectionReason"
                        disabled={
                          filteredData.requestingParticipant === ispb ||
                          filteredData.status !== "OPEN"
                        }
                        label="Refund Rejection reason"
                        select
                      >
                        <BaasMenuItem value="NO_BALANCE">
                          No balance
                        </BaasMenuItem>
                        <BaasMenuItem value="ACCOUNT_CLOSURE">
                          Account closure
                        </BaasMenuItem>
                        <BaasMenuItem value="CANNOT_REFUND">
                          Cannot refund
                        </BaasMenuItem>
                      </BaasTextField>
                    </BaasGrid>

                    <BaasGrid item xs={12}>
                      <BaasButton
                        backgroundColor="black"
                        variant="contained"
                        onClick={() => setOpen(true)}
                        disabled={
                          filteredData.requestingParticipant === ispb ||
                          filteredData.status !== "OPEN"
                        }
                        loading={isSubmitting}
                      >
                        Close Refund
                      </BaasButton>
                    </BaasGrid>
                    <BaasGrid item xs={12}>
                      <BaasText color="error">{errorMessage()}</BaasText>
                    </BaasGrid>
                  </BaasGrid>

                  <JsonStyled theme={theme}>
                    <ReactJson
                      src={filteredData}
                      style={{ height: "100%", overflowY: "scroll" }}
                      displayObjectSize={false}
                      displayDataTypes={false}
                    />
                  </JsonStyled>
                </BoxStyled>
              </Form>
            )}
          </Formik>
        )}
      </BaasModal>
    </>
  );
};

export default RefundSearchListModal;
