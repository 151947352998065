import { useTheme } from "@mui/system";
import BaasButton from "components/atoms/Button/Button";
import BaasContainer from "components/atoms/Container/Container";
import { default as BaasGrid } from "components/atoms/Grid/Grid";
import BaasText from "components/atoms/Text/BaasText";
import BaasTextField from "components/atoms/TextField/TextField";
import { Form, Formik } from "formik";
import { toast } from "react-toastify";
import * as yup from "yup";
import React, { useCallback, useState } from "react";
import { ReactComponent as Logo } from "assets/btg-logo-md.svg";
import BaasFlex from "components/atoms/Flex/Flex";
import { useHistory } from "react-router";
import { getPathParams } from "helpers/getPathParams";
import { mutationUserPassword } from "api/security-api";
import { useQueryGetUserPasswordToken } from "api/security-api";
import { useMutation } from "react-query";
import NewPasswordOtp from "./NewPasswordOtp";
import { toastMessages } from "constants/toastMessages";

const NewPassword = () => {
  const theme = useTheme();
  const history = useHistory();
  const queryToken = getPathParams("t");
  const [newOtp, setNewOtp] = useState({ state: false, loginReturn: {} });

  const { data, isError } = useQueryGetUserPasswordToken(
    {
      params: queryToken,
    },
    { retry: false }
  );

  if (isError) {
    toast.error("Your link is broken");
    history.push("/login");
  }

  const validationSchema = yup.object().shape({
    newPassword: yup
      .string()
      .required("No password provided.")
      .min(8, "Password is too short - should be 8 chars minimum")
      .matches(
        /^.*(?=.{8,})((?=.*[!@#$%^&*()\-_=+{};:,<.>]){1})(?=.*\d)((?=.*[a-z]){1})((?=.*[A-Z]){1}).*$/,
        "Password must contain at least 8 characters, one uppercase, one number and one special case character"
      ),
    confirmPassword: yup
      .string()
      .required("No password provided.")
      .oneOf([yup.ref("newPassword"), null], "Passwords must match"),
    emailToken: yup.string().required("No password provided."),
  });

  const { mutate: registerPassword } = useMutation(
    (e) => mutationUserPassword(e),
    {
      onError: (error) => {
        if (error.status === 400) {
          toast.error(toastMessages.newPassword.error[400]);
        } else if (error.status === 403) {
          toast.error(toastMessages.newPassword.error[403]);
        } else if (error.status === 404) {
          toast.error(toastMessages.newPassword.error[404]);
        } else if (error.status === 409) {
          toast.error(toastMessages.newPassword.error[409]);
        } else if (error.status === 428) {
          toast.error(toastMessages.newPassword.error[428]);
        }
      },
      onSuccess: (success) => {
        if (success.qrCodeOtpUri) {
          toast.success(toastMessages.newPassword.success[200]);
          setNewOtp({ state: true, loginReturn: success });
        } else {
          toast.success(toastMessages.newPassword.success[204]);
          history.push("/login");
        }
      },
    }
  );

  const onSubmitPassword = useCallback(
    (values, formik) => {
      registerPassword(
        {
          userId: data.userId,
          password: values.newPassword,
          code: values.emailToken,
          transactionSession: data.transactionSession,
        },
        {
          onSettled: () => {
            formik.setSubmitting(false);
          },
        }
      );
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [data]
  );

  return (
    <>
      <BaasContainer width="100%">
        {newOtp.state ? (
          <NewPasswordOtp data={data} newOtp={newOtp} />
        ) : (
          <Formik
            initialValues={{}}
            onSubmit={onSubmitPassword}
            validationSchema={validationSchema}
          >
            {({ isSubmitting, values, ...props }) => (
              <Form>
                <BaasGrid container sx={{ marginTop: "32px" }}>
                  <BaasGrid xs={0} md={3} />
                  <BaasGrid
                    xs={12}
                    md={6}
                    sx={{
                      backgroundColor: theme.palette.grey[300],
                      borderRadius: "4px",
                    }}
                    p="32px"
                  >
                    <BaasFlex style={{ marginBottom: "16px" }}>
                      <Logo />
                    </BaasFlex>

                    <BaasGrid p="0px 0px 32px 0px">
                      <BaasText variant="h4" color="grey.700">
                        Register your password
                      </BaasText>
                    </BaasGrid>

                    <BaasGrid>
                      <BaasText variant="body2" color="grey.700">
                        Your password change is almost done! Please fill the
                        fields below with your new password and just click on
                        change password. Dont forget: your password needs to be
                        at least 8 digits long, contain an upper and a lowercase
                        letter, at least one number and a special character.
                      </BaasText>
                    </BaasGrid>

                    <BaasGrid>
                      <BaasTextField
                        fullWidth
                        required
                        type="password"
                        name="newPassword"
                        label="New password"
                      />
                    </BaasGrid>

                    <BaasGrid p="8px 0px">
                      <BaasTextField
                        fullWidth
                        required
                        type="password"
                        name="confirmPassword"
                        label="Confirm password"
                      />
                    </BaasGrid>

                    <BaasGrid p="8px 0px">
                      <BaasText variant="body2" color="grey.700">
                        Please fill bellow with the verification code received
                        in your email account.
                      </BaasText>
                    </BaasGrid>

                    <BaasGrid p="8px 0px">
                      <BaasTextField
                        fullWidth
                        required
                        // type="number"
                        name="emailToken"
                        label="Email token"
                      />
                    </BaasGrid>

                    <BaasGrid
                      display="flex"
                      justifyContent="center"
                      p="16px 0px 0px 0px"
                    >
                      <BaasButton
                        type="submit"
                        size="medium"
                        variant="contained"
                        loading={isSubmitting}
                      >
                        Register password
                      </BaasButton>
                    </BaasGrid>
                  </BaasGrid>
                </BaasGrid>
              </Form>
            )}
          </Formik>
        )}
      </BaasContainer>
    </>
  );
};

export default NewPassword;
