import { useTheme } from "@mui/system";
import BaasFlex from "components/atoms/Flex/Flex";
import BaasGrid from "components/atoms/Grid/Grid";
import BaasNotification from "components/atoms/Notificacion/Notification";
import BaasText from "components/atoms/Text/BaasText";
import { useField, useFormikContext } from "formik";
import { useCallback, useEffect } from "react";
import { isEdited } from "./utils";

const WebhookConfigSideBar = ({ subscriptions, initialValues }) => {
  const theme = useTheme();
  const { values } = useFormikContext();
  const [{ value }, , { setValue }] = useField("webhookOptions");
  const [{ value: webhookOptions }] = useField("resources");
  const [{ value: edited }] = useField("edited");

  const handleSelectResource = useCallback(
    (subscription) => {
      const entity = webhookOptions.find(
        (option) => option.entity === subscription.entity
      );
      setValue({ ...entity, text: entity.name });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [value, webhookOptions]
  );

  const statusConverter = (subscription) => {
    let status = {},
      color = "success";
    status.saved = true;
    if (subscription.qtyConsecutiveErrors > 0) {
      color = "warning";
      status.warning = true;
    }
    if (subscription.blockedManyErrors) {
      color = "error";
      status.error = true;
    }

    return { color, status };
  };

  useEffect(() => {
    if (
      values.webhookOptions?.entity &&
      isEdited(initialValues, values, values.webhookOptions?.index)
    ) {
      edited.push(values.resources[values.webhookOptions.index]);
    }
  }, [values, edited, initialValues]);

  return (
    <BaasGrid>
      {edited.length > 0 && (
        <>
          <BaasGrid p="16px 0px">
            <BaasText sx={{ paddingLeft: "16px" }} variant="subtitle1">
              Edited
            </BaasText>
          </BaasGrid>

          <BaasGrid p="12px" sx={{ background: theme.palette.grey[100] }}>
            <BaasFlex flexDirection="column">
              {edited?.map((subscription) => (
                <BaasNotification
                  onClick={() => handleSelectResource(subscription)}
                  color="primary"
                  label={subscription.name}
                  status={{ edited: true }}
                />
              ))}
            </BaasFlex>
          </BaasGrid>
        </>
      )}

      <BaasGrid p="16px 0px">
        <BaasText sx={{ paddingLeft: "16px" }} variant="subtitle1">
          Subscriptions
        </BaasText>
      </BaasGrid>

      <BaasGrid p="12px" sx={{ background: theme.palette.grey[100] }}>
        <BaasFlex flexDirection="column">
          {subscriptions.map((subscription) => (
            <BaasNotification
              onClick={() => handleSelectResource(subscription)}
              color={statusConverter(subscription).color}
              label={subscription.entity}
              status={statusConverter(subscription).status}
            />
          ))}
        </BaasFlex>
      </BaasGrid>
    </BaasGrid>
  );
};

export default WebhookConfigSideBar;
