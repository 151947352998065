import { useTheme } from "@mui/system";
import BaasGrid from "components/atoms/Grid/Grid";
import BaasText from "components/atoms/Text/BaasText";

const BaasCardWithIcon = ({ title, img, ...props }) => {
  const theme = useTheme();
  return (
    <>
      <BaasGrid
        spacing={2}
        p={1}
        sx={{
          borderRadius: "4px",
          backgroundColor: theme.palette.grey[100],
          maxWidth: 320,
        }}
      >
        <BaasGrid container>
          <BaasGrid p="8px 0px">
            <BaasText variant="h6">
              <span>
                <img
                  src={img}
                  alt=""
                  style={{
                    background: theme.palette.grey[400],
                    width: "36px",
                    height: "36px",
                    borderRadius: "100%",
                    textAlign: "center",
                    verticalAlign: "middle",
                    padding: "6px",
                  }}
                />
              </span>{" "}
              {title}
            </BaasText>
          </BaasGrid>
        </BaasGrid>
      </BaasGrid>
    </>
  );
};

export default BaasCardWithIcon;
