import { useTheme } from "@mui/system";
import { queryGetPixDictRefund } from "api/dictv2-api";
import BaasButton from "components/atoms/Button/Button";
import BaasCheckbox from "components/atoms/Checkbox/Checkbox";
import BaasDatePicker from "components/atoms/DatePicker/DatePicker";
import BaasGrid from "components/atoms/Grid/Grid";
import BaasMenuItem from "components/atoms/MenuItem/MenuItem";
import BaasText from "components/atoms/Text/BaasText";
import BaasTextField from "components/atoms/TextField/TextField";
import { toastMessages } from "constants/toastMessages";
import { Form, Formik } from "formik";
import _ from "lodash";
import moment from "moment";
import { useAccount } from "providers/account";
import React, { useCallback, useState } from "react";
import { useHistory } from "react-router";
import { toast } from "react-toastify";
import RefundSearchList from "./RefundSearchList";

const RefundSearch = (props) => {
  const theme = useTheme();
  const { accountType } = useAccount();
  const [data, setData] = useState([]);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [queryVariables, setQueryVariables] = useState();
  const [inputFilter, setInputFilter] = useState();
  const [pagination, setPagination] = useState();

  const history = useHistory();

  const handleAppendData = (response, oldData) => {
    if (accountType === "Indirect") {
      setPagination({
        hasMoreElements: response.body?.hasMoreElements,
        lastModified:
          response.body?.items[response.body.items.length - 1]?.lastModified,
      });
      setData(
        oldData
          ? _.uniqBy([...oldData, ...response.body?.items], "id")
          : response.body?.items
      );
    } else {
      if (oldData) {
        setPagination({
          hasMoreElements: response.body?.hasMoreElements,
          nextPage: response.body?.nextPage,
        });
        setData([...oldData, ...response.body?.items]);
      } else {
        setPagination({
          hasMoreElements: response.body?.hasMoreElements,
          nextPage: response.body?.nextPage,
        });
        setData(response.body?.items);
      }
    }
  };

  const searchRefund = useCallback(async (values, oldData) => {
    const response = await queryGetPixDictRefund(values);
    if (response.status < 300) {
      if (response.body.items.length === 0)
        toast.warning(toastMessages.search.searchEmpty);

      handleAppendData(response, oldData);
    } else {
      if ("errors" in response) {
        response.errors.map((error) => {
          return toast.error(error.message);
        });
      }
      setData([]);
    }
    setIsSubmitting(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleRedirect = useCallback(() => {
    history.push("/pix/v2/refund/create");
  }, [history]);

  const handleRefetch = () => {
    setIsSubmitting(true);
    searchRefund(queryVariables);
    setInputFilter(null);
  };

  const onSubmit = useCallback(
    (values) => {
      setIsSubmitting(true);
      const params = {
        modifiedAfter: `${moment(values.modifiedAfter).format(
          "YYYY-MM-DD"
        )}T00:00:00Z`,
        modifiedBefore: `${moment(values.modifiedBefore).format(
          "YYYY-MM-DD"
        )}T23:59:59Z`,
        status: values.status,
        limit: values.limit,
        participantRole: values.participantRole,
        includeDetails: values.includeDetails,
        accountType: accountType,
        taxId: values.taxId,
      };
      setQueryVariables(params);
      setInputFilter(null);
      searchRefund(params);
    },
    [accountType, searchRefund]
  );

  const handleLoadMore = () => {
    const queryPaginatedVariables =
      accountType === "Indirect"
        ? {
            ...queryVariables,
            modifiedAfter: pagination.lastModified,
          }
        : {
            ...queryVariables,
            nextPage: pagination.nextPage,
          };
    setQueryVariables(queryPaginatedVariables);
    setInputFilter(null);
    setIsSubmitting(true);
    searchRefund(queryPaginatedVariables, data);
  };
  const isPixLimitValidatorRoute =
    props?.location?.pathname === "/pix-limit-validator-med";

  return (
    <>
      <Formik
        initialValues={{
          limit: "20",
        }}
        onSubmit={onSubmit}
        enableReinitialize
      >
        {({ values, setFieldValue, ...props }) => {
          // let modifiedBefore = moment(values.modifiedBefore);
          // let modifiedAfter = moment(values.modifiedAfter);
          // const differenceInDates = modifiedBefore.diff(modifiedAfter, "days");
          // const errorInDates = differenceInDates > 2 || differenceInDates < 0;
          return (
            <Form>
              <BaasGrid p="16px" sx={{ background: theme.palette.grey[100] }}>
                <BaasText>
                  The refund request can be made by the paying user’s PSP, on
                  its own initiative or at the user’s request, in cases where
                  there is a well-founded suspicion of using the device to
                  practice fraud or an operational failure in the IT
                  infrastructure system of any of the participants involved in
                  the transaction.
                </BaasText>

                {!isPixLimitValidatorRoute && (
                  <>
                    {accountType !== "Merchant" && (
                      <BaasGrid item p="16px 0px">
                        <BaasButton
                          onClick={handleRedirect}
                          variant="contained"
                          backgroundColor="black"
                          type="submit"
                        >
                          Create new Refund Request
                        </BaasButton>
                      </BaasGrid>
                    )}
                  </>
                )}
                <BaasGrid container spacing={3}>
                  <BaasGrid item xs={12} sm={6}>
                    <BaasDatePicker
                      name="modifiedAfter"
                      label="Modified after"
                      // customOnChange={(date) => {
                      //   setFieldValue(
                      //     "modifiedBefore",
                      //     moment(date).add(3, "days")
                      //   );
                      // }}
                      fullWidth
                    />
                  </BaasGrid>

                  <BaasGrid item xs={12} sm={6}>
                    <BaasDatePicker
                      name="modifiedBefore"
                      label="Modified before"
                      // maxDate={moment(values.modifiedAfter).add(3, "days")}
                      // minDate={moment(values.modifiedAfter)}
                      fullWidth
                    />
                  </BaasGrid>

                  {accountType === "Indirect" && (
                    <BaasGrid item xs={12} sm={6}>
                      <BaasTextField
                        fullWidth
                        name="taxId"
                        label="Tax Id"
                        type="text"
                      />
                    </BaasGrid>
                  )}

                  <BaasGrid item xs={12} sm={6}>
                    <BaasTextField
                      fullWidth
                      name="participantRole"
                      label="Participant role"
                      required
                      select
                    >
                      <BaasMenuItem value="REQUESTING">Requesting</BaasMenuItem>
                      <BaasMenuItem value="CONTESTED">Contested</BaasMenuItem>
                    </BaasTextField>
                  </BaasGrid>

                  <BaasGrid item xs={12} sm={6}>
                    <BaasTextField
                      fullWidth
                      name="status"
                      label="Status"
                      select
                    >
                      <BaasMenuItem value="">--</BaasMenuItem>
                      <BaasMenuItem value="OPEN">Open</BaasMenuItem>
                      <BaasMenuItem value="CLOSED">Closed</BaasMenuItem>
                      <BaasMenuItem value="CANCELLED">Cancelled</BaasMenuItem>
                    </BaasTextField>
                  </BaasGrid>

                  <BaasGrid item xs={12} sm={6}>
                    <BaasTextField
                      fullWidth
                      name="limit"
                      label="Max items displayed"
                      select
                      required
                    >
                      <BaasMenuItem value="20">20</BaasMenuItem>
                      <BaasMenuItem value="50">50</BaasMenuItem>
                      <BaasMenuItem value="100">100</BaasMenuItem>
                    </BaasTextField>
                  </BaasGrid>

                  <BaasGrid item xs={12}>
                    <BaasCheckbox
                      name="includeDetails"
                      label="include refund details (Detail fields)"
                      fullWidth
                    />
                  </BaasGrid>
                </BaasGrid>
                <BaasGrid item p="16px 0px">
                  <BaasButton
                    variant="contained"
                    backgroundColor="black"
                    type="submit"
                    loading={isSubmitting}
                    // disabled={errorInDates}
                  >
                    Search
                  </BaasButton>
                </BaasGrid>
              </BaasGrid>
            </Form>
          );
        }}
      </Formik>
      {data.length > 0 && (
        <RefundSearchList
          data={data}
          loading={isSubmitting}
          handleRefetch={handleRefetch}
          loadMore={handleLoadMore}
          inputFilter={inputFilter}
          useLoadMore={accountType === "Indirect"}
          setInputFilter={setInputFilter}
          pagination={pagination}
        />
      )}
    </>
  );
};

export default RefundSearch;
