import DownloadIcon from "@mui/icons-material/Download";
import { Box, Button, useTheme } from "@mui/material";
import BaasGrid from "components/atoms/Grid/Grid";
import BaasSkeleton from "components/atoms/Skeleton/Skeleton";
import DinamicTable from "components/organism/DinamicTable/DinamicTable";
import moment from "moment";
export default function PixLimitValidatorReportsList({
  data,
  loading,
  loadMore,
  pagination,
}) {
  const theme = useTheme();
  const onDownloadClick = async (item) => {
    const fileUrl = item.reportDownloadUrl || item.url;

    const fileName = `Report${item.id}`;
    const link = document.createElement("a");
    link.href = fileUrl;
    link.download = fileName;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const checkIsFileExpired = (item) => {
    const fileUrl = item.reportDownloadUrl || item.url;
    if (fileUrl) {
      const url = new URL(fileUrl);
      const params = url.searchParams;
      let amazonExpiresInMinutes = "";
      params.forEach((value, key) => {
        if (key === "X-Amz-Expires") {
          amazonExpiresInMinutes = value / 60;
        }
      });
      const expiredDate = new moment(item.creationTime).add(
        amazonExpiresInMinutes,
        "minutes"
      );
      const currentDate = new moment();
      if (expiredDate.isAfter(currentDate)) {
        return false;
      } else {
        return true;
      }
    }
    return true;
  };

  if (loading) {
    return (
      <>
        <BaasSkeleton width="100%" height="65px" />
        <BaasSkeleton width="100%" height="65px" />
        <BaasSkeleton width="100%" height="65px" />
        <BaasSkeleton width="100%" height="65px" />
        <BaasSkeleton width="100%" height="65px" />
      </>
    );
  }

  const conditionalAction = (item) => {
    const disabled = item.status === "ERROR" || checkIsFileExpired(item);

    let conditions = [
      {
        onClick: disabled ? () => {} : (item) => onDownloadClick(item),
        icon: DownloadIcon,
        tooltip: disabled ? "Report expired or with error" : "Download",
        color: disabled ? theme.palette.grey[500] : null,
      },
    ];
    return conditions;
  };

  return (
    <BaasGrid style={{ marginTop: "16px" }}>
      <DinamicTable
        loading={loading}
        canDownloadCsv={data?.length > 0}
        data={data}
        disableRowHover={true}
        tableSchema={[
          {
            header: "Id",
            key: "id",
          },
          {
            header: "Created At",
            key: "creationTime",
            formatValue: (value) =>
              value ? moment(value).format("DD/MM/YYYY H:mm:ss") : "N/A",
          },

          {
            header: "Status",
            key: "status",
            extractItemValue: (item) =>
              checkIsFileExpired(item) ? "Expired" : item.status,
            formatValue: (value) => moment(value).format("DD/MM/YYYY H:mm:ss"),
          },
        ]}
        getExtraActions={(data) => conditionalAction(data)}
      />
      <Box width="100%" display="flex" justifyContent="flex-end" mt={2}>
        <Button
          variant="outlined"
          disabled={!Boolean(pagination?.hasMoreElements)}
          onClick={() => loadMore()}
        >
          Load more results
        </Button>
      </Box>
    </BaasGrid>
  );
}
