import { useTheme } from "@mui/system";
import { ReactComponent as VisibilityOff } from "assets/icons/Visibility/Off.svg";
import { ReactComponent as VisibilityOn } from "assets/icons/Visibility/On.svg";
import BaasFlex from "components/atoms/Flex/Flex";
import BaasGrid from "components/atoms/Grid/Grid";
import BaasIconButton from "components/atoms/IconButton/IconButton";
import BaasText from "components/atoms/Text/BaasText";
import { currencyFormatter } from "helpers/currencyFormatter";
import { useAccount } from "providers/account";
import React from "react";
import styled from "styled-components";
import { ReactComponent as LogoWhite } from "../../../../../assets/btg-logo-sm-white.svg";

const CardStyle = styled.div`
  background: ${(props) =>
    props.selected
      ? props.theme.palette.primary.main
      : props.theme.palette.grey[100]};
  cursor: ${(props) => (props.selected ? "default" : "pointer")};

  border-radius: 4px;
  height: 120px;
  width: 210px;
  min-width: 210px;
  padding: 16px;
  margin: 4px;
  :hover {
    background: ${(props) =>
      props.selected
        ? props.theme.palette.primary.main
        : props.theme.palette.grey[300]};
  }

  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

const AccountCard = ({ account, index }) => {
  // console.log(account);
  const theme = useTheme();
  const {
    accountSelected,
    setAccountSelected,
    handleShowBalance,
    showBalance,
  } = useAccount();

  if (!accountSelected.accountId && index === 0) {
    setAccountSelected({ index, ...account });
  }

  const isSelected = false;

  const getBalanceValue = () => {
    if (!showBalance) {
      return "*****";
    }

    const balance = account.availableBalance;
    if (balance === undefined || balance === null) {
      return "Balance N/A";
    }

    return `${currencyFormatter(balance / 100)} BRL`;
  };

  const accountTypeLabel = {
    TransactionalAccount: "Transactional",
    ProprietaryAccount: "Proprietary Account",
    ExternalAccount: "External Account",
  };

  return (
    <CardStyle selected={isSelected} theme={theme}>
      <BaasGrid display="flex" justifyContent="space-between">
        <BaasText variant="caption" color="grey.700">
          <strong>{accountTypeLabel[account.type] || "N/A"}</strong>
        </BaasText>
      </BaasGrid>

      <BaasGrid display="flex" alignItems="center">
        {account.type === "ExternalAccount" ? (
          <>
            <BaasText>Account {account.account}</BaasText>
          </>
        ) : (
          <>
            <BaasText color={isSelected ? "grey.100" : "grey.700"} variant="h6">
              {getBalanceValue()}
            </BaasText>
            <BaasIconButton
              variant="text"
              backgroundColor="black"
              size="small"
              onClick={handleShowBalance}
            >
              {showBalance ? (
                <VisibilityOff
                  stroke={
                    isSelected
                      ? theme.palette.grey[100]
                      : theme.palette.grey[800]
                  }
                />
              ) : (
                <VisibilityOn
                  stroke={
                    isSelected
                      ? theme.palette.grey[100]
                      : theme.palette.grey[800]
                  }
                />
              )}
            </BaasIconButton>
          </>
        )}
      </BaasGrid>

      <BaasFlex alignItems="center" justifyContent="space-between">
        {account.type !== "ExternalAccount" ? (
          <LogoWhite />
        ) : (
          <BaasText color={"grey.600"} fontSize="12px">
            Bank {account.bankCode || "N/A"}
          </BaasText>
        )}
      </BaasFlex>
    </CardStyle>
  );
};

export default AccountCard;
