import BaasRow from "components/atoms/Grid/Row";
import BaasGrid from "components/atoms/Grid/Grid";
import React, { useCallback, useMemo } from "react";
import BaasTextField from "components/atoms/TextField/TextField";
import { Form, Formik } from "formik";
import BaasButton from "components/atoms/Button/Button";
import { useTheme } from "@mui/system";
import BaasFlex from "components/atoms/Flex/Flex";
import BaasPageHeader from "components/molecules/PageHeader/PageHeader";
import BaasContentGrid from "components/templates/ContentGrid/ContentGrid";
import BaasText from "components/atoms/Text/BaasText";
import { useMutation, useQueryClient } from "react-query";
import { useOtp } from "providers/oneTimePassword";
import BaasOtpModal from "components/organism/OtpModal/OtpModal";
import { useQueryGetPixPaymentApprovalEndpoint } from "api/pix-api";
import getSafe from "helpers/getSafe";
import { mutationPutPixPaymentApprovalEndpoint } from "api/pix-api";
import { toast } from "react-toastify";
import BaasLoading from "components/molecules/Loading/Loading";

const PixCashUrlRegistration = () => {
  const theme = useTheme();
  const queryClient = useQueryClient();
  const { setOpen, isOtpRequired } = useOtp();

  const { data, isLoading } = useQueryGetPixPaymentApprovalEndpoint();
  const initialValues = useMemo(() => {
    return {
      validationUrl: getSafe(data, "body.validationUrl", ""),
      secretKey: getSafe(data, "body.secret", ""),
    };
  }, [data]);

  const { mutate: mutatePix } = useMutation((e) =>
    mutationPutPixPaymentApprovalEndpoint(e)
  );

  const onSubmit = useCallback(
    (values, formik) => {
      mutatePix(
        {
          otp: values.otp || undefined,

          validationUrl: values.validationUrl,
          secret: values.secretKey,
        },
        {
          onSuccess: (data) => {
            toast.success("Saved sucessfull");
            setOpen(false);

            queryClient.invalidateQueries(
              useQueryGetPixPaymentApprovalEndpoint
            );
          },
          onError: (error) => {
            toast.error("Error");
          },
          onSettled: () => {
            formik.setSubmitting(false);
          },
        }
      );
    },
    [mutatePix, setOpen, queryClient]
  );
  if (isLoading) {
    return <BaasLoading />;
  }
  return (
    <>
      <BaasFlex flex={1} flexDirection="column">
        <BaasPageHeader>Pix</BaasPageHeader>

        <BaasContentGrid spacing={4}>
          <Formik
            initialValues={initialValues}
            enableReinitialize
            onSubmit={isOtpRequired ? () => setOpen(true) : onSubmit}
          >
            {({ values, isSubmitting, ...props }) => (
              <Form>
                <BaasOtpModal
                  onConfirm={() => onSubmit(values, props)}
                  {...props}
                />
                <BaasGrid item spacing={1}>
                  <BaasRow>
                    <BaasText variant="h6">Pix cash URL registration</BaasText>
                  </BaasRow>
                </BaasGrid>

                <BaasRow>
                  <BaasGrid
                    p="16px"
                    sx={{ background: theme.palette.grey[100] }}
                  >
                    <BaasRow container column={12} spacing={3}>
                      <BaasGrid item xs={12}>
                        <BaasText variant="body2">
                          Use this session to register an endpoint to approve
                          your inbound PixPayments. This endpoint will be called
                          every time a new PixPayment arrive to your clients.
                        </BaasText>
                      </BaasGrid>

                      <BaasGrid item xs={12}>
                        <BaasText variant="body2">
                          The purpose of this endpoint is for you to approve (or
                          not) the arrival of new transactions.
                        </BaasText>
                      </BaasGrid>

                      <BaasGrid item xs={12} sm={6}>
                        <BaasTextField
                          fullWidth
                          name="validationUrl"
                          label="Validation URL"
                          required
                        />
                      </BaasGrid>

                      <BaasGrid item xs={12} sm={6}>
                        <BaasTextField
                          fullWidth
                          name="secretKey"
                          label="Secret key"
                          required
                        />
                      </BaasGrid>
                    </BaasRow>
                    <BaasRow
                      container
                      sx={{ justifyContent: "space-between" }}
                      flexDirection="row"
                    >
                      <BaasGrid p={0} item>
                        <BaasButton
                          type="submit"
                          size="medium"
                          variant="contained"
                          backgroundColor="black"
                          loading={isSubmitting}
                        >
                          Save
                        </BaasButton>
                      </BaasGrid>
                    </BaasRow>
                  </BaasGrid>
                </BaasRow>
              </Form>
            )}
          </Formik>
        </BaasContentGrid>
      </BaasFlex>
    </>
  );
};

export default PixCashUrlRegistration;
