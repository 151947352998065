import { Box, Button, Alert } from "@mui/material";
import { useTheme } from "@mui/system";
import BaasFlex from "components/atoms/Flex/Flex";
import BaasGrid from "components/atoms/Grid/Grid";
import BaasRow from "components/atoms/Grid/Row";
import BaasText from "components/atoms/Text/BaasText";
import BaasPageHeader from "components/molecules/PageHeader/PageHeader";
import BaasContentGrid from "components/templates/ContentGrid/ContentGrid";
import BaasTransactionSuccess from "components/templates/TransactionSuccess/TransactionSuccess";
import React, { useState, useCallback, useMemo } from "react";
import BaasButton from "components/atoms/Button/Button";
import { useCustomMutation } from "helpers/useCustomMutation";
import {
  mutationPixPaymentTesterDict,
  mutationPixPaymentTesterDictBulk,
} from "api/baasTester/pixPaymentTester.api";
import { toast } from "react-toastify";
import BaasTextFieldCurrency from "components/molecules/TextFieldCurrency/TextFieldCurrency";
import * as yup from "yup";
import BaasTextField from "components/atoms/TextField/TextField";
import { Form, Formik } from "formik";
import DebitPartFormInput from "./components/DebitPartFormInput";
import BaasTable from "components/atoms/Table/Table";
import BaasTableHeader from "components/atoms/Table/TableHeader";
import BaasTableHeaderCol from "components/atoms/Table/TableHeaderCol";
import BaasTableRow from "components/atoms/Table/TableRow";
import BaasTableCol from "components/atoms/Table/TableCol";
import { currencyFormatter } from "helpers/currencyFormatter";
import InsertDriveFileIcon from "@mui/icons-material/InsertDriveFile";
import { csvmaker } from "helpers/csvMaker";
import { downloadCsvFile } from "helpers/csvMaker";

const PixPaymentTester = () => {
  const theme = useTheme();
  const [success, setSuccess] = useState(false);
  const {
    mutate: mutateSingle,
    isSubmitting: isSubmittingSingle,
  } = useCustomMutation(mutationPixPaymentTesterDict);
  const {
    mutate: mutateBulk,
    isSubmitting: isSubmittingBulk,
  } = useCustomMutation(mutationPixPaymentTesterDictBulk);
  const [useDebitPart, setUseDebitPart] = useState(false);

  const initialValues = useMemo(() => {
    return {
      amount: "",
      creditedPixKey: "",
      paymentsNumber: 1,
    };
  }, []);

  const validationSchema = yup.object().shape({
    paymentsNumber: yup
      .number()
      .min(1, "Minimum value is 1")
      .max(50, "Maximum value is 50"),
  });

  const onSubmitPayment = useCallback(
    (values, resetForm) => {
      if (Number(values.paymentsNumber) === 1) {
        mutateSingle(
          {
            values,
          },
          (data) => {
            setUseDebitPart(false);
            setSuccess([data.body?.body?.body]);
          },
          (error) => {
            return toast.error(error.message);
          }
        );
        return;
      }

      if (Number(values.paymentsNumber) > 1) {
        const payload = {
          ...values,
          quantity: Number(values.paymentsNumber),
        };
        mutateBulk(
          {
            payload,
          },
          (data) => {
            setUseDebitPart(false);
            setSuccess(data.body);
          },
          (error) => {
            return toast.error(error.message);
          }
        );
        return;
      }

      return toast.error("Invalid number of payments");
    },
    [mutateBulk, mutateSingle]
  );

  const toggleExpand = (values, setValues, props) => {
    if (!useDebitPart === false) {
      setValues({
        ...values,
        customDebitPartName: "",
        customDebitPartTaxId: "",
        customDebitPartBranch: "",
        customDebitPartAccount: "",
        customDebitPartAccountType: "",
      });
      setUseDebitPart(!useDebitPart);
    } else {
      setUseDebitPart(!useDebitPart);
    }
  };

  const handleDownload = () => {
    if (success) {
      const data = success.map((item) => {
        return {
          amount: `R$ ${currencyFormatter(item.amount)}`,
          endToEndId: item.endToEndId,
        };
      });

      const csvdata = csvmaker(data);
      downloadCsvFile(csvdata, "Pix Payment Tester Data");
    }
  };

  const getTotalValue = (values) => {
    if (values.paymentsNumber && values.amount) {
      const total = values.paymentsNumber * values.amount;
      console.log(total);
      return total;
    } else {
      return 0;
    }
  };

  return (
    <>
      <BaasFlex flex={1} flexDirection="column">
        {!success ? (
          <>
            <BaasPageHeader>Test Interface</BaasPageHeader>
            <BaasGrid
              item
              spacing={1}
              style={{ padding: "16px", marginBottom: "16px" }}
            >
              <BaasRow>
                <BaasText variant="h6">PIX Payment Tester</BaasText>
              </BaasRow>
            </BaasGrid>
            <BaasContentGrid spacing={4}>
              <BaasGrid
                item
                spacing={1}
                sx={{ background: theme.palette.grey[100] }}
              >
                <BaasGrid
                  item
                  style={{ padding: "16px", paddingBottom: "0px" }}
                >
                  <Box display="flex"></Box>
                </BaasGrid>
                <BaasGrid item style={{ padding: "16px" }}>
                  <Formik
                    initialValues={initialValues}
                    onSubmit={onSubmitPayment}
                    validationSchema={validationSchema}
                  >
                    {({ values, errors, isValid, setValues, ...props }) => (
                      <Form>
                        <BaasText variant="body2">
                          Please, insert the credited PIX key to proceed for
                          payment process, the debit participant and amount is
                          optional. The limit of payments is 50.
                        </BaasText>
                        <BaasRow>
                          <BaasGrid
                            sx={{ background: theme.palette.grey[100] }}
                          >
                            <BaasRow container column={12} spacing={3}>
                              <BaasGrid item xs={6}>
                                <BaasTextField
                                  fullWidth
                                  required
                                  name="creditedPixKey"
                                  label="Credited PIX Key"
                                />
                              </BaasGrid>
                              <BaasGrid item xs={6}>
                                <BaasTextFieldCurrency
                                  fullWidth
                                  name="amount"
                                  label="Amount (Random if is R$0.00)"
                                  errors={errors}
                                />
                              </BaasGrid>
                              <BaasGrid item xs={6}>
                                <BaasTextField
                                  fullWidth
                                  label="Number of Payments (Max 50)"
                                  name="paymentsNumber"
                                  type="number"
                                  required
                                />
                              </BaasGrid>

                              <DebitPartFormInput
                                values={values}
                                expanded={useDebitPart}
                                error={errors}
                                toggleExpand={() =>
                                  toggleExpand(values, setValues)
                                }
                              />
                              {getTotalValue(values) > 10000 ? (
                                <>
                                  <BaasGrid xs={12} item>
                                    <Alert
                                      severity="error"
                                      style={{ margin: "8px 0" }}
                                    >
                                      {`Your PIX Payment total is R$ 
                                   ${currencyFormatter(getTotalValue(values))}
                                     .Total amount of all payments cannot exceed
                                  R$10.000.`}
                                    </Alert>
                                  </BaasGrid>
                                </>
                              ) : (
                                <></>
                              )}

                              <BaasGrid p={0} item>
                                <BaasButton
                                  type="submit"
                                  disabled={getTotalValue(values) > 10000}
                                  size="medium"
                                  variant="contained"
                                  backgroundColor="primary"
                                  loading={
                                    isSubmittingBulk || isSubmittingSingle
                                  }
                                >
                                  {isSubmittingBulk || isSubmittingSingle
                                    ? "Processing Payment"
                                    : "Start Payment"}
                                </BaasButton>
                              </BaasGrid>
                            </BaasRow>
                          </BaasGrid>
                        </BaasRow>
                      </Form>
                    )}
                  </Formik>
                </BaasGrid>
              </BaasGrid>
            </BaasContentGrid>
          </>
        ) : (
          <BaasTransactionSuccess
            onClick={() => {
              setSuccess(undefined);
            }}
            customChildren={
              <Box style={{ minWidth: "500px" }}>
                <Box style={{ padding: "16px", paddingLeft: "24px" }}>
                  <Box minWidth="150px" mb={"16px"}>
                    <Button
                      startIcon={<InsertDriveFileIcon />}
                      onClick={handleDownload}
                    >
                      Download Data
                    </Button>
                  </Box>
                  <BaasTable>
                    <BaasTableHeader>
                      <BaasTableHeaderCol>Amount</BaasTableHeaderCol>
                      <BaasTableHeaderCol width="64px">
                        End to End
                      </BaasTableHeaderCol>
                    </BaasTableHeader>
                    <Formik initialValues={{}}>
                      {({ values, isSubmitting, ...props }) => (
                        <Form>
                          {(success || []).map((item) => (
                            <>
                              <BaasTableRow>
                                <BaasTableCol>{`R$ ${currencyFormatter(
                                  item.amount
                                )}`}</BaasTableCol>
                                <BaasTableCol>{item.endToEndId}</BaasTableCol>
                              </BaasTableRow>
                            </>
                          ))}
                        </Form>
                      )}
                    </Formik>
                  </BaasTable>
                </Box>
              </Box>
            }
          />
        )}
      </BaasFlex>
    </>
  );
};

export default PixPaymentTester;
