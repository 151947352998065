import { useTheme } from "@mui/system";
import { mutationPostPicInfractionReport } from "api/dictv2-api";
import BaasButton from "components/atoms/Button/Button";
import BaasFlex from "components/atoms/Flex/Flex";
import BaasGrid from "components/atoms/Grid/Grid";
import BaasRow from "components/atoms/Grid/Row";
import BaasMenuItem from "components/atoms/MenuItem/MenuItem";
import BaasText from "components/atoms/Text/BaasText";
import BaasTextField from "components/atoms/TextField/TextField";
import BaasPageHeader from "components/molecules/PageHeader/PageHeader";
import BaasOtpModal from "components/organism/OtpModal/OtpModal";
import BaasContentGrid from "components/templates/ContentGrid/ContentGrid";
import BaasTransactionSuccess from "components/templates/TransactionSuccess/TransactionSuccess";
import { toastMessages } from "constants/toastMessages";
import { Form, Formik } from "formik";
import { useAccount } from "providers/account";
import { useOtp } from "providers/oneTimePassword";
import React, { useCallback, useMemo, useState } from "react";
import { useHistory } from "react-router";
import { toast } from "react-toastify";
import * as yup from "yup";
import AccountTypeSelectedLabel from "components/molecules/AccountTypeSelectedLabel/AccountTypeSelectedLabel";

const InfractionReportCreateV2 = () => {
  const theme = useTheme();
  const { setOpen, isOtpRequired } = useOtp();
  const { accountType } = useAccount();
  const [success, setSuccess] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const history = useHistory();
  const initialValues = useMemo(() => {
    return {};
  }, []);

  console.log(accountType);

  const validationSchema = yup.object().shape({});

  const mutateInfractionReport = useCallback(async (values) => {
    const response = await mutationPostPicInfractionReport(values);
    if (response.status < 300) {
      toast.success(toastMessages.requestSuccess);
      setOpen(false);
      setSuccess(true);
    } else {
      if ("errors" in response) {
        response.errors.map((error) => {
          return toast.error(error.message);
        });
      }
    }
    setIsSubmitting(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleBack = () => {
    history.push("/pix/v2/infraction-report");
  };

  const onSubmit = useCallback(
    (values) => {
      setIsSubmitting(true);

      mutateInfractionReport({
        otp: values.otp,
        reportDetails: values.reportDetails,
        transactionId: values.transactionId,
        accountType: accountType,
        reason: values.reason,
        situationType: values.situationType,
      });
    },
    [mutateInfractionReport, accountType]
  );

  return (
    <>
      <BaasFlex flex={1} flexDirection="column">
        <BaasPageHeader>DICT Hub</BaasPageHeader>

        <BaasContentGrid spacing={4}>
          <Formik
            initialValues={initialValues}
            onSubmit={isOtpRequired ? () => setOpen(true) : onSubmit}
            validationSchema={validationSchema}
          >
            {({ values, ...props }) => (
              <Form>
                <BaasOtpModal
                  onConfirm={() => onSubmit(values, props)}
                  {...props}
                />
                <BaasGrid p="16px 0px" item>
                  <BaasGrid display="flex">
                    <BaasText variant="h6">Create New Infraction Report</BaasText>
                    <AccountTypeSelectedLabel />
                  </BaasGrid>
                </BaasGrid>
                {!success ? (
                  <BaasRow>
                    <BaasGrid
                      p="16px"
                      sx={{ background: theme.palette.grey[100] }}
                    >
                      <BaasText variant="body2">
                        Creates an infraction report. Both the debited and the
                        credited participant can create an infraction report.
                      </BaasText>

                      <BaasRow container column={12} spacing={3}>
                        <BaasGrid item xs={12} sm={6}>
                          <BaasTextField
                            fullWidth
                            name="reason"
                            label="Reason"
                            select
                            required
                          >
                            <BaasMenuItem value="REFUND_REQUEST">
                              Refund Request
                            </BaasMenuItem>
                            <BaasMenuItem value="REFUND_CANCELLED">
                              Refund Cancelled
                            </BaasMenuItem>
                          </BaasTextField>
                        </BaasGrid>

                        <BaasGrid item xs={12} sm={6}>
                          <BaasTextField
                            fullWidth
                            name="situationType"
                            label="Situation type"
                            select
                            required
                          >
                            <BaasMenuItem value="SCAM">Scam</BaasMenuItem>
                            <BaasMenuItem value="ACCOUNT_TAKEOVER">
                              Account Takeover
                            </BaasMenuItem>
                            <BaasMenuItem value="COERCION">
                              Coercion
                            </BaasMenuItem>
                            <BaasMenuItem value="FRAUDULENT_ACCESS">
                              Fraudulent Access
                            </BaasMenuItem>
                            {accountType !== "Merchant" && (
                              <BaasMenuItem value="OTHER">Other</BaasMenuItem>
                            )}
                            {accountType !== "Merchant" && (
                              <BaasMenuItem value="UNKNOWN">
                                Unknown
                              </BaasMenuItem>
                            )}
                          </BaasTextField>
                        </BaasGrid>

                        <BaasGrid item xs={12} sm={6}>
                          <BaasTextField
                            fullWidth
                            required
                            name="transactionId"
                            label="Transaction Id"
                          />
                        </BaasGrid>

                        {accountType !== "Merchant" && (
                          <BaasGrid item xs={12} sm={6}>
                            <BaasTextField
                              fullWidth
                              required
                              name="reportDetails"
                              label="Report details"
                            />
                          </BaasGrid>
                        )}
                      </BaasRow>

                      <BaasRow
                        container
                        sx={{ justifyContent: "space-between" }}
                        flexDirection="row"
                      >
                        <BaasGrid p={0} item>
                          <BaasButton
                            type="submit"
                            size="medium"
                            variant="outlined"
                            backgroundColor="black"
                            onClick={handleBack}
                          >
                            Back
                          </BaasButton>
                        </BaasGrid>
                        <BaasGrid p={0} item>
                          <BaasButton
                            type="submit"
                            size="medium"
                            variant="contained"
                            backgroundColor="black"
                            loading={isSubmitting}
                          >
                            Create
                          </BaasButton>
                        </BaasGrid>{" "}
                      </BaasRow>
                    </BaasGrid>
                  </BaasRow>
                ) : (
                  <BaasTransactionSuccess redirectUrl="/pix/v2/infraction-report" />
                )}
              </Form>
            )}
          </Formik>
        </BaasContentGrid>
      </BaasFlex>
    </>
  );
};

export default InfractionReportCreateV2;
