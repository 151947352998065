import { useTheme } from "@mui/system";
import { queryGetPixImpactsByTaxId } from "api/pixLimitValidator/pixLimitValidator";
import { useQueryGetUserInfo } from "api/security-api";
import BaasButton from "components/atoms/Button/Button";
import BaasDatePicker from "components/atoms/DatePicker/DatePicker";
import BaasFlex from "components/atoms/Flex/Flex";
import BaasGrid from "components/atoms/Grid/Grid";
import BaasMenuItem from "components/atoms/MenuItem/MenuItem";
import BaasText from "components/atoms/Text/BaasText";
import BaasTextField from "components/atoms/TextField/TextField";
import AccountTypeSelectedLabel from "components/molecules/AccountTypeSelectedLabel/AccountTypeSelectedLabel";
import BaasPageHeader from "components/molecules/PageHeader/PageHeader";
import BaasContentGrid from "components/templates/ContentGrid/ContentGrid";
import { toastMessages } from "constants/toastMessages";
import { Form, Formik } from "formik";
import _ from "lodash";
import moment from "moment";
import React, { useCallback, useState } from "react";
import { toast } from "react-toastify";
import {
  queryGetInfractionFile,
  queryGetScoreFile,
  queryGetAuthorizedInstitutionsFile,
} from "api/pixLimitValidator/pixLimitValidator";
import PixLimitValidatorReportsList from "./PixLimitValidatorReportsList";

const PixLimitValidatorReportDownload = () => {
  const theme = useTheme();
  const [loading, setLoading] = useState(false);
  const [pagination, setPagination] = useState();
  const [queryVariables, setQueryVariables] = useState();
  const [paginatedData, setPaginatedData] = useState([]);

  const getQueryByEntity = (entity) => {
    if (entity === "AUTHORIZED_PARTICIPANTS") {
      return queryGetAuthorizedInstitutionsFile;
    }
    if (entity === "INFRACTION") {
      return queryGetInfractionFile;
    }
    return queryGetScoreFile;
  };

  const handleSearch = useCallback(async (values, oldData) => {
    if (!oldData) {
      setLoading(true);
    }

    const query = getQueryByEntity(values.entity);
    const response = await query(values);
    if (response.status < 300) {
      handleAppendData(response, oldData);
    } else {
      if (response.status === 404) {
        setPaginatedData([]);
        toast.warning(toastMessages.search.searchEmpty);
      }
      if ("errors" in response) {
        response.errors.map((error) => {
          return toast.error(error.message);
        });
        setPaginatedData([]);
      }
    }
    setLoading(false);
  }, []);

  const handleAppendData = (response, oldData) => {
    const formatedList = response.body.items;
    if (formatedList === 0) toast.warning(toastMessages.search.searchEmpty);
    if (oldData) {
      setPagination({
        hasMoreElements: response.body?.hasMoreElements,
        lastModified: formatedList[formatedList.length - 1]?.lastModified,
      });
      setPaginatedData(
        oldData ? _.uniqBy([...oldData, ...formatedList], "id") : formatedList
      );
    } else {
      setPagination({
        hasMoreElements: response.body?.hasMoreElements,
        lastModified: formatedList[formatedList.length - 1]?.lastModified,
      });
      setPaginatedData(formatedList);
    }
  };

  const onSearch = useCallback(
    (values) => {
      setLoading(true);
      const params = {
        startDate: `${moment(values.startDate).format("YYYY-MM-DD")}T00:00:00Z`,
        endDate: `${moment(values.endDate).format("YYYY-MM-DD")}T23:59:59Z`,
        entity: values.entity,
        limit: 50,
      };
      setQueryVariables(params);
      handleSearch(params);
    },
    [handleSearch]
  );

  const handleLoadMore = () => {
    const queryPaginatedVariables = {
      ...queryVariables,
      startDate: pagination.lastModified,
    };
    setQueryVariables(queryPaginatedVariables);
    handleSearch(queryPaginatedVariables, paginatedData);
  };

  return (
    <BaasFlex flexDirection="column" width="100%">
      <BaasPageHeader>Pix Limit Validator</BaasPageHeader>
      <BaasContentGrid spacing={4}>
        <BaasGrid p="16px 0px" item>
          <BaasGrid display="flex">
            <BaasText variant="h6">Report Download</BaasText>
            <AccountTypeSelectedLabel />
          </BaasGrid>
        </BaasGrid>
        <Formik
          initialValues={{
            startDate: moment().subtract(3, "days"),
            endDate: moment(),
          }}
          onSubmit={onSearch}
        >
          {({ values, setFieldValue, ...props }) => {
            return (
              <Form>
                <BaasGrid p="16px" sx={{ background: theme.palette.grey[100] }}>
                  <BaasText>
                    This page contains the search engine for all reports that
                    were generated for this client. You can download them in the
                    list bellow.
                  </BaasText>
                  <BaasGrid container spacing={3}>
                    <BaasGrid item xs={12}>
                      <BaasTextField
                        fullWidth
                        name="entity"
                        label="Entity"
                        required
                        select
                      >
                        <BaasMenuItem value="AUTHORIZED_PARTICIPANTS">
                          Authorized Participants
                        </BaasMenuItem>
                        <BaasMenuItem value="INFRACTION">
                          Infraction
                        </BaasMenuItem>
                        <BaasMenuItem value="SCORE">Score</BaasMenuItem>
                      </BaasTextField>
                    </BaasGrid>

                    <>
                      <BaasGrid item xs={12} sm={6}>
                        <BaasDatePicker
                          name="startDate"
                          label="Start Date"
                          fullWidth
                        />
                      </BaasGrid>
                      <BaasGrid item xs={12} sm={6}>
                        <BaasDatePicker
                          name="endDate"
                          label="End Date"
                          required
                          fullWidth
                        />
                      </BaasGrid>
                    </>
                  </BaasGrid>
                  <BaasGrid item p="16px 0px">
                    <BaasButton
                      variant="contained"
                      backgroundColor="black"
                      loading={false}
                      type="submit"
                    >
                      Search
                    </BaasButton>
                  </BaasGrid>
                </BaasGrid>
              </Form>
            );
          }}
        </Formik>
        <PixLimitValidatorReportsList
          data={paginatedData}
          loading={loading}
          loadMore={handleLoadMore}
          pagination={pagination}
        />
      </BaasContentGrid>
    </BaasFlex>
  );
};
export default PixLimitValidatorReportDownload;
